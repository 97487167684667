import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {/*<GridToolbarExport />*/}
        <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', minHeight: '20px', fontSize: 'x-large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}
const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', minHeight: '20px', fontSize: 'x-large', marginTop: '1%', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const companyOptions = [
    {value: 'SKSO', label: 'SKSO'}
]

const contractOptions = [
    {value: 'ALL', label: 'ALL'},
    {value: 'AMZL', label: 'AMZL'}
]

const vatChargeableOption = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'}
]

const statusOptions = [
    {value: 'Active', label: 'Active'},
    {value: 'Inactive', label: 'Inactive'},
]

const animatedComponents = makeAnimated();

const areaOptions = [
    {value: 'ALL', label: 'ALL'},
    {value: 'DME4-SKSO', label: 'DME4-SKSO'},
    {value: 'DRM4-SKSO', label: 'DRM4-SKSO'},
    {value: 'DSS2-SKSO', label: 'DSS2-SKSO'},
    {value: 'DXE1-SKSO', label: 'DXE1-SKSO'}
]

const RateCard = () =>{
    const [ data, setData ] = useState([])
    const [ displayData, setDisplayData ] = useState([])
    const [ openAdd, setOpenAdd ] = useState(false)
    const [ company, setCompany ] = useState()
    const [ contract, setContract ] = useState()
    const [ short, setShort ] = useState()
    const [ description, setDescription ] = useState()
    const [ duration, setDuration ] = useState()
    const [ vatChargeable, setVatChargeable ] = useState()
    const [ rateSubIn, setRateSubIn ] = useState()
    const [ rateSubOut, setRateSubOut ] = useState()
    const [ status, setStatus ] = useState()
    const [ notes, setNotes ] = useState()
    const [ area, setArea ] = useState()
    const [ areaOptionsD, setAreaOptionsD ] = useState([])
    const [ warningCompany, setWarningCompany ] = useState("")
    const [ warningContract, setWarningContract ] = useState("")
    const [ warningShort, setWarningShort ] = useState("")
    const [ warningDescription, setWarningDescription ] = useState("")
    const [ warningDuration, setWarningDuration ] = useState("")
    const [ warningVatChargeable, setWarningVatChargeable ] = useState("")
    const [ warningRateSubIn, setWarningRateSubIn ] = useState("")
    const [ warningRateSubOut, setWarningRateSubOut ] = useState("")
    const [ warningArea, setWarningArea ] = useState("")
    const [ warningStatus, setWarningStatus ] = useState("")
    const [ id, setId ] = useState()
    const [ openDel, setOpenDel ] = useState(false)
    const [ openEdit, setOpenEdit ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    useEffect(() =>{
        setLoading(true)
        UserService.getRateCard().then(
            response =>{
                setData(response.data)
                setLoading(false)
            }
        ).catch(err => console.log(err.message))
    }, [])

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
        {
            field: 'company',
            headerName: 'Company',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'contract',
            headerName: 'Contract',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'schedule_shortcode',
            headerName: 'Schedule Short',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'description',
            headerName: 'Description',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'duration',
            headerName: 'Duration',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'vat_chargeable',
            headerName: 'Vat Chargeable',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'rate_subtotal_in',
            headerName: 'Rate Subtotal in',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'rate_subtotal_out',
            headerName: 'Rate Subtotal out',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'area',
            headerName: 'Area',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'edit',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <EditIcon onClick={e => handleOpenEdit(param.row)} />
                    </>
            )
        },
        {
            field: 'delete',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <DeleteOutlineIcon onClick={e => deleteRow(param.row)} />
                    </>
            )
        }
    ]

    useEffect(() => {
        let dataPlc = []
        data?.map(row =>{
            dataPlc.push({id: row.entry, company: row.company, contract: row.contract, schedule_shortcode: row.schedule_shortcode, description: row.description, duration: row.duration, vat_chargeable: row.vat_chargeable, rate_subtotal_in: row.rate_subtotal_in, rate_subtotal_out: row. rate_subtotal_out, area: row, area: row.area, status: row.status, notes: row.notes})
        })
        dataPlc.reverse()
        setDisplayData(dataPlc)
    }, [data])

    const handleSearch = (e) =>{
        let dataPlc = []
        data?.map(row =>{
            if(row?.description.toLowerCase().match(e.target.value?.toLowerCase())){
                dataPlc.push({id: row.entry, company: row.company, contract: row.contract, schedule_shortcode: row.schedule_shortcode, description: row.description, duration: row.duration, vat_chargeable: row.vat_chargeable, rate_subtotal_in: row.rate_subtotal_in, rate_subtotal_out: row. rate_subtotal_out, area: row, area: row.area, status: row.status, notes: row.notes})
            }
        })
        dataPlc.reverse()
        setDisplayData(dataPlc)
    }

    const handleAddClose = () =>{
        setCompany("")
        setContract("")
        setShort("")
        setDescription("")
        setDuration("")
        setVatChargeable("")
        setRateSubIn("")
        setRateSubOut("")
        setArea("")
        setStatus("")
        setNotes("")
        setAreaOptionsD([{value: '', label: ''}])
        setOpenAdd(false)
    }

    const handleOpenAdd = (e) =>{
        setOpenAdd(true)
    }

    useEffect(() =>{
        let data = area?.split(',')
        let dataPlc = []
        if(area){
            data?.forEach(element => {
                dataPlc.push({value: element, label: element})
            });
            setAreaOptionsD(dataPlc)
        }else{
            setAreaOptionsD([{value: '', label: ''}])
        }
       
    },[area])

    const submit = () =>{
       // if(company && contract && short && description && duration && vatChargeable && rateSubIn && rateSubOut && area && status){
            const dataPlc = {company: company, contract: contract, schedule_shortcode: short, description: description, duration: duration, vat_chargeable: vatChargeable, rate_subtotal_in: rateSubIn, rate_subtotal_out: rateSubOut, area: area, status: status, notes: notes ? notes : ''}
            
            UserService.insertRate(dataPlc).then(
                response =>{
                    window.location.reload(false)
                }
            ).catch(err => console.log(err.message))
        /*}else{
            if(!company){
                setWarningCompany('field required')
            }
            if(!contract){
                setWarningContract('field required')
            }
            if(!short){
                setWarningShort('field required')
            }
            if(!description){
                setWarningDescription('field required')
            }
            if(!duration){
                setWarningDuration('field required')
            }
            if(!vatChargeable){
                setWarningVatChargeable('field required')
            }
            if(!rateSubIn){
                setWarningRateSubIn('field required')
            }
            if(!rateSubOut){
                setWarningRateSubOut('field required')
            }
            if(!area){
                setWarningArea('field required')
            }
            if(!status){
                setWarningStatus('field required')
            }
        }*/
    }

    useEffect(() =>{
        if(company){
            setWarningCompany("")
        }
        if(contract){
            setWarningContract("")
        }
        if(short){
            setWarningShort("")
        }
        if(description){
            setWarningDescription("")
        }
        if(duration){
            setWarningDuration("")
        }
        if(vatChargeable){
            setWarningVatChargeable("")
        }
        if(rateSubIn){
            setWarningRateSubIn("")
        }
        if(rateSubOut){
            setWarningRateSubOut("")
        }
        if(area){
            setWarningArea("")
        }
        if(status){
            setWarningStatus("")
        }
    }, [company, contract, short, description, duration, vatChargeable, rateSubIn, rateSubOut, area, status])

    const deleteRow = (params) =>{
        console.log(params)
        setId(params.id)
        setDescription(params.description)
        setOpenDel(true)
    }
    
    const handleDelClose = () =>{
        setId("")
        setDescription("")
        setOpenDel(false)
    }

    const deleteRowSend = () =>{
        const dataPlc = {id: id}
        UserService.deleteRate(dataPlc).then(
            response =>{
                window.location.reload(false)
            }
        ).catch(err => console.log(err.message))
    }

    const handleOpenEdit = (params) =>{
        console.log(params)
        const areaPlc = params.area
        const len = areaOptions.length
        let dataPlc = []
        setArea(areaPlc)
        setCompany(params.company)
        setContract(params.contract)
        setShort(params.schedule_shortcode)
        setDescription(params.description)
        setDuration(params.duration)
        setVatChargeable(params.vat_chargeable)
        setId(params.id)
        setRateSubIn(params.rate_subtotal_in)
        setRateSubOut(params.rate_subtotal_out)
        setStatus(params.status)
        setNotes(params.notes)
        for(let i = 0; i < len; i++){
            if(areaPlc?.match(areaOptions[i]?.value)){
                console.log(areaOptions[i].value)
                console.log(areaPlc)
                dataPlc.push(areaOptions[i])
            }
        }
        console.log(dataPlc)
        setAreaOptionsD(dataPlc)
        setOpenEdit(true)
    }

    const handleEditClose = () =>{
        setOpenEdit(false)
    }

    const update = () =>{
     //   if(company && contract && short && description && duration && vatChargeable && rateSubIn && rateSubOut && area && status){
            const data = {entry: id, company: company, contract: contract, schedule_shortcode: short, description: description, duration: duration, vat_chargeable: vatChargeable, rate_subtotal_in: rateSubIn, rate_subtotal_out: rateSubOut, area: area, status: status, notes: notes}
            UserService.updateRate(data).then(
                response =>{
                    window.location.reload(false)
                }
            ).catch(err => console.log(err.message))
        /*}else{
            if(!company){
                setWarningCompany('field required')
            }
            if(!contract){
                setWarningContract('field required')
            }
            if(!short){
                setWarningShort('field required')
            }
            if(!description){
                setWarningDescription('field required')
            }
            if(!duration){
                setWarningDuration('field required')
            }
            if(!vatChargeable){
                setWarningVatChargeable('field required')
            }
            if(!rateSubIn){
                setWarningRateSubIn('field required')
            }
            if(!rateSubOut){
                setWarningRateSubOut('field required')
            }
            if(!area){
                setWarningArea('field required')
            }
            if(!status){
                setWarningStatus('field required')
            }
        }*/
        
    }

    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '70px'}}>Rate Card</h3>
            <div className="searchBar1 fixed1 " >
                {/*<input type="text" name='search' className="fullInput1" placeholder="search..." onChange={e => handleSearch(e)}  />*/}
                <button class="buttonSkOn" style={{marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => handleOpenAdd(e)}>
                    <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>New entry</span>
                    <div class="fill-container"></div>
                </button>
            </div>      
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    pagination
                    rows={displayData}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    loading={loading}
                    {...data}
                    //processRowUpdate={params =>handleCellEdit(params)}
                    onRowClick={params => console.log(params)}
                    onRowSelectionModelChange={params => console.log(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    columnVisibilityModel={{
                        id: false
                    }}
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                />
            </Box> 
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openAdd}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add Line</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Company</label>
                                    <Select 
                                        options={companyOptions}
                                        styles={colourStyles}
                                        value={{label: company, value: company}}
                                        onChange={e => setCompany(e.value)}
                                    />
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Contract</label>
                                    <Select 
                                        options={contractOptions}
                                        styles={colourStylesS}
                                        value={{label: contract, value: contract}}
                                        onChange={e => setContract(e.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Schedule Short</label>
                                    <input type="text" name="dlNo" className="lone" value={short} onChange={e => setShort(e.target.value)}></input>
                                </div>
                            </div>
                           
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Description</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={description} onChange={e => setDescription(e.target.value)}></input>
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Duration</label>
                                    <input type="number" name="origin" className="inputEdit correction" value={duration}  onChange={e => setDuration(e.target.value)}></input>
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Vat Chargeable</label>
                                    <Select 
                                        options={vatChargeableOption}
                                        styles={colourStyles}
                                        value={{label: vatChargeable, value: vatChargeable}}
                                        onChange={e => setVatChargeable(e.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Rate Subtotal in</label>
                                    <input type="number" name="dlEx" className="inputEdit" value={rateSubIn} onChange={e => setRateSubIn(e.target.value)}></input>
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Rate Subtotal out</label>
                                    <input type="number" name="origin" className="inputEdit correction" value={rateSubOut}  onChange={e => setRateSubOut(e.target.value)}></input>
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Area</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        styles={colourStyles}
                                        
                                        //defaultValue={areaOptionsD}
                                        isMulti
                                        options={areaOptions}
                                        onChange={e => {
                                            let data = ''
                                            e?.map(row =>{
                                                if(!data){
                                                    data = row.value
                                                }else{
                                                    data +=','+row.value
                                                }
                                            })
                                            setArea(data)
                                        }}
                                    />
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Status</label>
                                    <Select 
                                        options={statusOptions}
                                        styles={colourStylesS}
                                        value={{label: status, value: status}}
                                        onChange={e => setStatus(e.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Notes</label>
                                    <textarea type="text" name="dlNo" className="lone" value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={submit}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Add</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleAddClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openDel}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Are you sure you want to delete the below?</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Description: {description}</label>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={deleteRowSend}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Yes</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleDelClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>No</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openEdit}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add Line</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Company</label>
                                    <Select 
                                        options={companyOptions}
                                        styles={colourStyles}
                                        value={{label: company, value: company}}
                                        onChange={e => setCompany(e.value)}
                                    />
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Contract</label>
                                    <Select 
                                        options={contractOptions}
                                        styles={colourStylesS}
                                        value={{label: contract, value: contract}}
                                        onChange={e => setContract(e.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Schedule Short</label>
                                    <input type="text" name="dlNo" className="lone" value={short} onChange={e => setShort(e.target.value)}></input>
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Description</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={description} onChange={e => setDescription(e.target.value)}></input>
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Duration</label>
                                    <input type="number" name="origin" className="inputEdit correction" value={duration}  onChange={e => setDuration(e.target.value)}></input>
                                </div>
                            </div>
                           
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Vat Chargeable</label>
                                    <Select 
                                        options={vatChargeableOption}
                                        styles={colourStyles}
                                        value={{label: vatChargeable, value: vatChargeable}}
                                        onChange={e => setVatChargeable(e.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Rate Subtotal in</label>
                                    <input type="number" name="dlEx" className="inputEdit" value={rateSubIn} onChange={e => setRateSubIn(e.target.value)}></input>
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Rate Subtotal out</label>
                                    <input type="number" name="origin" className="inputEdit correction" value={rateSubOut}  onChange={e => setRateSubOut(e.target.value)}></input>
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Area</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        styles={colourStyles}
                                        value={areaOptionsD ? areaOptionsD : ''}
                                        isMulti
                                        options={areaOptions}
                                        onChange={e => {
                                            let data = ''
                                            e?.map(row =>{
                                                if(!data){
                                                    data = row.value
                                                }else{
                                                    data +=','+row.value
                                                }
                                            })
                                            setArea(data)
                                        }}
                                    />
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Status</label>
                                    <Select 
                                        options={statusOptions}
                                        styles={colourStylesS}
                                        value={{label: status, value: status}}
                                        onChange={e => setStatus(e.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Notes</label>
                                    <textarea type="text" name="dlNo" className="lone" value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={update}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Update</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleEditClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default RateCard;