import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Papa from 'papaparse';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import columns from "./scoreCard";
import Select from 'react-select';

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={{fields: ['week', 'station', 'amzl_id', 'delivered', 'dcr', 'dnr_dpmo', 'pod', 'cc', 'ce', 'dex', 'concessions']}} />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const ScorecardDataOsm = () =>{
    const [ displayData, setDisplayData ] = useState([])

    useEffect(()=>{
        UserService.getScoreCardData().then(
            response =>{
                console.log(response.data)
                const data = response.data
                let dataPlc = []
                data?.map(row =>{
                    dataPlc.push({id: row.entry, week: row.week,  station: row.station,associate: row.name, amzl_id: row.amzl_id, delivered: row.delivered, dcr: row.dcr+'%', dnr_dpmo: row.dnr_dpmo, pod: row.pod+'%', cc: row.cc+'%', ce: row.ce, dex: row.dex+'%', concessions: row.concessions})
                })
                setDisplayData(dataPlc)
            }
        ).catch(err => console.log(err))
    },[])

    return(
        <div className="dashArch">
            <h3 className="h3TitlesCorrection" style={{marginBottom: '50px'}}>Score Card Data (OSM)</h3>
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop: 5}}>
                <StripedDataGridPro
                    pagination
                    //checkboxSelection
                    rows={displayData}
                    
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 50,
                        },
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[50, 100, 200]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    //slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                    //loading={loading}
                    //processRowUpdate={params =>handleCellEdit(params)}
                    //onRowSelectionModelChange={params => setSelectedIds(params)}
                    //onRowClick={params => handleAddName(params.row)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    //onColumnVisibilityModelChange={param => setHidenColumns(param)}    
                    //columnVisibilityModel={hidenColumns}
                    //ignoreValueFormatterDuringExport
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                    {...displayData}
                />
            </Box>
        </div>
    )
}
export default ScorecardDataOsm;