const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150, hide: true },
     {
        field: 'document_no',
        headerName: 'Document No',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
   
    {
        field: 'sk_no',
        headerName: 'Sk No',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'associate_name',
        headerName: 'Associate Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'category',
        headerName: 'Category',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'schedule_date',
        headerName: 'Schedule Date',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'description',
        headerName: 'Description',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    /*{
        field: 'duration',
        headerName: 'Planed Duration',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },*/
    {
        field: 'route',
        headerName: 'Route No',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'rate',
        headerName: 'Rate',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'qty',
        headerName: 'Qty',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'sub_total',
        headerName: 'Subtotal',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
    },
    {
        field: 'vat',
        headerName: 'Vat',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'week_no',
        headerName: 'Week No',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'station',
        headerName: 'Station',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'updatedBy',
        headerName: 'Created/Updated By',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_wave_time',
        headerName: 'Wave Time',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_dispatch_time',
        headerName: 'Dispatch Time',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_end_time',
        headerName: 'End Time',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_actual_duration',
        headerName: 'Actual Duration',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_late_wave',
        headerName: 'Late Wave',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_notes',
        headerName: 'Orh Notes',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    /*{
        field: 'edit',
        headerName: '',
        headerClassName: 'table-header',
        width: 50,
        editable: false,
        renderCell: (param) =>(
                <>
                    <EditIcon onClick={e => handleOpenEdit(param.row)} />
                </>
        )
    },
    {
        field: 'delete',
        headerName: '',
        headerClassName: 'table-header',
        width: 50,
        editable: false,
        renderCell: (param) =>(
                <>
                    <DeleteOutlineIcon onClick={e => deleteRow(param.row)} />
                </>
        )
    }*/
]
export default columns;