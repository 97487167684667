import React from "react";
import { useState, useEffect } from "react";
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import UserService from "../../services/user.service";

const UserDetails = () => {
    const [ userDetails, setUserDetails ] = useState()
    const [ loading, setLoading ] = useState(false)
    const [ search, setSearch ] = useState([])

    useEffect(()=>{
        setLoading(true)
        UserService.getUserDetails().then(
            response => {
                console.log(response.data)
                setUserDetails(response.data)
                setLoading(false)
            }
        )
    },[])

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
        {
            field: 'account_no',
            headerName: 'Account No',
            headerClassName: 'table-header',
            width: 208,
            editable: false,
          },
        {
          field: 'name',
          headerName: 'Name',
          headerClassName: 'table-header',
          width: 288,
          editable: false,
        },
        {
          field: 'station',
          headerName: 'Station',
          headerClassName: 'table-header',
          width: 288,
          editable: false,
        },
       {
          field: 'skso_role',
          headerName: 'Skso Role',
          headerClassName: 'table-header',
          width: 200,
          editable: false,
        },
        {
            field: 'amzl_id',
            headerName: 'Amzl Id',
            headerClassName: 'table-header',
            width: 300,
            editable: false,
          },
          {
            field: 'first_name',
            headerName: 'First Name',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'last_name',
            headerName: 'Last Name',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'phone_no',
            headerName: 'Phone No',
            headerClassName: 'table-header',
            width: 228,
            editable: false,
          },
          {
            field: 'email_w',
            headerName: 'Work email',
            headerClassName: 'table-header',
            width: 368,
            editable: false,
          },
          {
            field: 'email_p',
            headerName: 'Personal Email',
            headerClassName: 'table-header',
            width: 368,
            editable: false,
          },
          {
            field: 'address1',
            headerName: 'Address1',
            headerClassName: 'table-header',
            width: 368,
            editable: false,
          },
          {
            field: 'address2',
            headerName: 'Address2',
            headerClassName: 'table-header',
            width: 368,
            editable: false,
          },
          {
            field: 'town',
            headerName: 'Town',
            headerClassName: 'table-header',
            width: 208,
            editable: false,
          },
          {
            field: 'county',
            headerName: 'County',
            headerClassName: 'table-header',
            width: 208,
            editable: false,
          },
          {
            field: 'post_code',
            headerName: 'Post Code',
            headerClassName: 'table-header',
            width: 208,
            editable: false,
          },
          {
            field: 'nationality',
            headerName: 'Nationality',
            headerClassName: 'table-header',
            width: 208,
            editable: false,
          },
          {
            field: 'dob',
            headerName: 'Dob',
            headerClassName: 'table-header',
            width: 208,
            editable: false,
          },
          {
            field: 'nino',
            headerName: 'Nino',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'utr',
            headerName: 'Utr',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'dl_no',
            headerName: 'Dl No',
            headerClassName: 'table-header',
            width: 250,
            editable: false,
          },
          {
            field: 'dl_expiry',
            headerName: 'Dl Expiry',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'dl_points',
            headerName: 'Dl Points',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'dl_origin',
            headerName: 'Dl Origin',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'vehicle_source',
            headerName: 'Vehicle Source',
            headerClassName: 'table-header',
            width: 250,
            editable: false,
          },
          {
            field: 'pay_cycle',
            headerName: 'Pay Cycle',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'company_name',
            headerName: 'Company Name',
            headerClassName: 'table-header',
            width: 300,
            editable: false,
          },
          {
            field: 'company_reg',
            headerName: 'Company Reg',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'compnay_vat',
            headerName: 'Company Vat',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'bank_holder',
            headerName: 'Bank Holder',
            headerClassName: 'table-header',
            width: 368,
            editable: false,
          },
          {
            field: 'bank_name',
            headerName: 'Bank Name',
            headerClassName: 'table-header',
            width: 368,
            editable: false,
          },
          {
            field: 'bank_ref',
            headerName: 'Bank Ref',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'bank_sc',
            headerName: 'Bank SC',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'bank_acc',
            headerName: 'Bank ACC',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'notes',
            headerName: 'notes',
            headerClassName: 'table-header',
            width: 368,
            editable: false,
          },
        /*{
            field: 'edit',
            headerName: '',
            headerClassName: 'table-header',
            width: 288,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <EditIcon onClick={e => handleParams(param.row)} onMouseEnter={handlePopoverOpenE} onMouseLeave={handlePopoverCloseE}/>
                        <KeyIcon style={{paddingLeft: '5%', fontSize:'40px'}} onClick={e => handleAccess(param)} onMouseEnter={handlePopoverOpenA} onMouseLeave={handlePopoverCloseA}/>
                        <DeleteIcon style={{paddingLeft: '5%', fontSize:'40px'}} onClick={e => handleAlert(param)} onMouseEnter={handlePopoverOpenD} onMouseLeave={handlePopoverCloseD}/>
                    </>
            )
          },*/
        ]
        let display = []
        userDetails?.map(detail =>{
            display.push({
                id: detail.entry, 
                account_no: detail.account_no, 
                address1: detail.address1, 
                address2: detail.address2,
                amzl_id: detail.amzl_id,
                bank_acc: detail.bank_acc,
                bank_holder: detail.bank_holder,
                bank_name: detail.bank_name,
                bank_ref: detail.bank_ref,
                bank_sc: detail.bank_sc,
                company_name: detail.company_name,
                company_reg: detail.company_reg,
                compnay_vat: detail.compnay_vat,
                county: detail.county,
                dl_expiry: detail.dl_expiry,
                dl_no: detail.dl_no,
                dl_origin: detail.dl_origin,
                dl_points: detail.dl_points,
                dob: detail.dob,
                email_p: detail.email_p,
                email_w: detail.email_w,
                first_name: detail.first_name,
                last_name: detail.last_name,
                name: detail.name,
                nationality: detail.nationality,
                nino: detail.nino,
                notes: detail.notes,
                pay_cycle: detail.pay_cycle,
                phone_no: detail.phone_no,
                post_code: detail.post_code,
                skso_role: detail.skso_role,
                station: detail.station,
                status: detail.status,
                town: detail.town,
                utr: detail.utr,
                vehicle_source: detail.vehicle_source 
            })
        })
        const handleSearch = (e) =>{
            const searchE = e.target.value
            setSearch([])
                userDetails?.map(detail =>{
                    let data = {
                        id: detail.entry, 
                        account_no: detail.account_no, 
                        address1: detail.address1, 
                        address2: detail.address2,
                        amzl_id: detail.amzl_id,
                        bank_acc: detail.bank_acc,
                        bank_holder: detail.bank_holder,
                        bank_name: detail.bank_name,
                        bank_ref: detail.bank_ref,
                        bank_sc: detail.bank_sc,
                        company_name: detail.company_name,
                        company_reg: detail.company_reg,
                        compnay_vat: detail.compnay_vat,
                        county: detail.county,
                        dl_expiry: detail.dl_expiry,
                        dl_no: detail.dl_no,
                        dl_origin: detail.dl_origin,
                        dl_points: detail.dl_points,
                        dob: detail.dob,
                        email_p: detail.email_p,
                        email_w: detail.email_w,
                        first_name: detail.first_name,
                        last_name: detail.last_name,
                        name: detail.name,
                        nationality: detail.nationality,
                        nino: detail.nino,
                        notes: detail.notes,
                        pay_cycle: detail.pay_cycle,
                        phone_no: detail.phone_no,
                        post_code: detail.post_code,
                        skso_role: detail.skso_role,
                        station: detail.station,
                        status: detail.status,
                        town: detail.town,
                        utr: detail.utr,
                        vehicle_source: detail.vehicle_source 
                    }
                    if(detail.name?.toLowerCase().match(searchE) || detail.station?.toLowerCase().match(searchE || detail.status?.toLowerCase().match(searchE) || detail.email_w?.toLowerCase().match(searchE))){
                        setSearch(oldSearch =>[...oldSearch, data])
                    }
                })
        }
    return(
        <>
            <div className="searchBar fixed">
                <input type="text" name='search' className="fullInput" placeholder="search user"/*defaultValue={email}*/ onChange={e => handleSearch(e)}  />
            </div>
            {search.length == 0 ?
                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%' }}>
                <DataGrid
                
            // checkboxSelection
                //onRowSelectionModelChange={params => setSelected(params)}
                rows={display}
                
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    },
                }}
                sx={{fontSize: '20px', fontFamily: ''}}
                pageSizeOptions={[100]}
                //onRowClick={params => handleRow(params)}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                //{...data}
                //processRowUpdate={handleCellEdit}
                //onCellClick={handleClick}
            
                />
            </Box>:
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%' }}>
                <DataGrid
                
            // checkboxSelection
                //onRowSelectionModelChange={params => setSelected(params)}
                rows={search}
                
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    },
                }}
                sx={{fontSize: '20px', fontFamily: ''}}
                pageSizeOptions={[100]}
                //onRowClick={params => handleRow(params)}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                //{...data}
                //processRowUpdate={handleCellEdit}
                //onCellClick={handleClick}
            
                />
            </Box>
            }
        </>
    )
}
export default UserDetails;