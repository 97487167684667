import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import { isEmail } from "validator";
import { withRouter } from '../common/with-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import loginBack from '../components/img/img.jpg';
import clip from './img/backTry.mp4';


const emailValidator = value => {
  
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeConfirmation = this.onChangeConfirmation.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      messageReset: "",
      step: 1,
      email: "",
      confirm: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }
  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }
  onChangeConfirmation(e) {
    this.setState({
      confirm: e.target.value
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.router.navigate("/profile");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  

  render() {
    const required = value => {
      console.log('aici')
      if (!value) {
        return (
          <div className="alert alert-danger" role="alert">
            This field is required!
          </div>
        );
      }else if (!isEmail(value) && this.state.step == 2) {
        return (
          <div className="alert alert-danger" role="alert">
            This is not a valid email.
          </div>
        );
      }
    };
    const handleClick = () =>{
      this.setState({step: this.state.step+1})
      console.log('Forgot Password')
    }
  const  handleForgot = (e) => {
      e.preventDefault()
      this.setState({
        messageReset: "",
        loading: true
      });
  
      if(this.checkBtn.context._errors.length === 0 && this.state.confirm == this.state.email){
        UserService.sendForgotEmail({email: this.state.email}).then(
          response => {
            console.log(response.data)
            this.setState({messageReset: response.data})
            this.setState({
          loading: false
        });
          }
        ).catch(
          err=>{
            console.log(err)
          }
        )
      }else{
        this.setState({messageReset: 'Pssword do not match with the confirmation!'})
        this.setState({
          loading: false
        });
      }
      this.setState({step: this.state.step+1})
    }
    return (
      <div className="dashArch" /*style={{backgroundImage: loginBack}}*/>
        <video id="background-video" className="videoCorrection" loop muted autoPlay>
            <source src={clip} type="video/mp4" />
            <source src={clip} type="video/ogg" />
            Your browser does not support the video tag.
        </video>
{ /*       <img src={loginBack} style={{position: 'fixed', top: 0, left:-20 , width: '100%', height: '100%', bbackgroundSize: 'auto'}} className="mobileImage"></img>
*/}          {this.state.step == 1 ?
            <div className="col-md-12">
              <div className="card card-container">
                <img
                  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  alt="profile-img"
                  className="profile-img-card"
                />

                <Form
                  onSubmit={this.handleLogin}
                  ref={c => {
                    this.form = c;
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="username">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="username"
                      value={this.state.username}
                      onChange={this.onChangeUsername}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={this.state.loading}
                    >
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                  </div>

                  <div className="form-group">
                    <a onClick={handleClick} style={{fontSize:'18px', color: '#0099cc'}}>Forgot Password</a>
                  </div>
                  {this.state.message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {this.state.message}
                      </div>
                    </div>
                  )}
                  <CheckButton
                    style={{ display: "none" }}
                    ref={c => {
                      this.checkBtn = c;
                    }}
                  />
                </Form>
              </div>
            </div>:  
            
            //-----------------forgot password form
            
            <div className="col-md-12">
              <div className="card card-container">
                <img
                  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  alt="profile-img"
                  className="profile-img-card"
                />

               
                <Form
                  onSubmit={e => handleForgot(e)}
                  ref={c => {
                    this.form = c;
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="email">Personal Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      validations={[required]}
                    /> 
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirm">Confirm Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="confirm"
                      value={this.state.confirm}
                      onChange={this.onChangeConfirmation}
                      validations={[required]}
                    />
                  </div>
                  

                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={this.state.loading}
                    >
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Reset</span>
                    </button>
                  </div>
                  <div className="form-group" onClick={e => this.setState({step: this.state.step-1})}>
                      <KeyboardBackspaceIcon sx={{color: '#12b9bd', paddingTop: '10px', fontSize: '50px'}} />
                  </div>
                 
                  {this.state.messageReset == 'Password reset email sent!' ? 
                    <div className="form-group">
                      <div className="alert alert-success" role="alert">
                        {this.state.messageReset}
                      </div>
                    </div>: this.state.messageReset && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {this.state.messageReset}
                      </div>
                    </div>)
                  }
                  <CheckButton
                    style={{ display: "none" }}
                    ref={c => {
                      this.checkBtn = c;
                    }}
                  />
                </Form>
              </div>
            </div>
      }
    </div>
    );
  }
}

export default withRouter(Login);