import React from "react";
import { LineChart } from '@mui/x-charts/LineChart';
import Select from 'react-select';
import UserService from "../../services/user.service";
import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { green, red } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { useYScale, useDrawingArea } from '@mui/x-charts/hooks';
import {  areaElementClasses } from '@mui/x-charts/LineChart';


function ColorSwich({ threshold, color1, color2, id }) {
    const { top, height, bottom } = useDrawingArea();
    const svgHeight = top + bottom + height;
  
    const scale = useYScale(); // You can provide the axis Id if you have multiple ones
    const y0 = scale(threshold); // The coordinate of of the origine
    const off = y0 !== undefined ? y0 / svgHeight : 0;
  
    return (
      <defs>
        <linearGradient
          id={id}
          x1="0"
          x2="0"
          y1="0"
          y2={`${svgHeight}px`}
          gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
        >
          <stop offset={off} stopColor={color1} stopOpacity={1} />
          <stop offset={off} stopColor={color2} stopOpacity={1} />
        </linearGradient>
      </defs>
    );
  }
  function ColorPalette({ id }) {
    const { top, height, bottom } = useDrawingArea();
    const svgHeight = top + bottom + height;
  
    const scale = useYScale(); // You can provide the axis Id if you have multiple ones
  
    return (
      <defs>
        <linearGradient
          id={id}
          x1="0"
          x2="0"
          y1="0"
          y2={`${svgHeight}px`}
          gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
        >
          <stop
            offset={scale(5000) / svgHeight}
            stopColor={green[400]}
            stopOpacity={1}
          />
          <stop
            offset={scale(4000) / svgHeight}
            stopColor={green[400]}
            stopOpacity={1}
          />
          <stop
            offset={scale(4000) / svgHeight}
            stopColor={green[300]}
            stopOpacity={1}
          />
          <stop
            offset={scale(3000) / svgHeight}
            stopColor={green[300]}
            stopOpacity={1}
          />
          <stop
            offset={scale(3000) / svgHeight}
            stopColor={green[200]}
            stopOpacity={1}
          />
          <stop
            offset={scale(2000) / svgHeight}
            stopColor={green[200]}
            stopOpacity={1}
          />
          <stop
            offset={scale(2000) / svgHeight}
            stopColor={green[100]}
            stopOpacity={1}
          />
          <stop
            offset={scale(1000) / svgHeight}
            stopColor={green[100]}
            stopOpacity={1}
          />
          <stop
            offset={scale(1000) / svgHeight}
            stopColor={green[50]}
            stopOpacity={1}
          />
          <stop offset={scale(0) / svgHeight} stopColor={green[50]} stopOpacity={1} />
          <stop offset={scale(0) / svgHeight} stopColor={red[100]} stopOpacity={1} />
          <stop
            offset={scale(-1000) / svgHeight}
            stopColor={red[100]}
            stopOpacity={1}
          />
          <stop
            offset={scale(-1000) / svgHeight}
            stopColor={red[200]}
            stopOpacity={1}
          />
          <stop
            offset={scale(-2000) / svgHeight}
            stopColor={red[200]}
            stopOpacity={1}
          />
          <stop
            offset={scale(-3000) / svgHeight}
            stopColor={red[300]}
            stopOpacity={1}
          />
        </linearGradient>
      </defs>
    );
  }

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%',maxWidth: '101%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '101%',
        //paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 50 },
    {
        field: 'updatedBy',
        headerName: 'Added by',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
       
      },
    {
        field: 'type',
        headerName: 'Type',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
    {
      field: 'location',
      headerName: 'Depot',
      headerClassName: 'table-header',
      width: 200,
      editable: false,
    },
    {
      field: 'week_no',
      headerName: 'Week',
      headerClassName: 'table-header',
      width: 200,
      editable: false,
    },
   {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'table-header',
      width: 200,
      editable: false,
    },
    {
        field: 'total_routes',
        headerName: 'Total Routes',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'depot_routes',
        headerName: 'Depot Routes',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'volume_share',
        headerName: 'Volume Share',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'tomorrows_routes',
        headerName: 'Tomorrows Routes',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'das_available',
        headerName: 'DAs Available',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'under_over',
        headerName: 'Spares',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
       
      },
      {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
       
      },
    ]

const Statistics = () =>{
    const [ depotOption, setDepotOption ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ rows, setRows ] = useState([])
    const [ xxxData, setXxxData ] = useState([])
    const [ shareData, setShareData ] = useState([])
    const [ depotRoutes, setDepotRoutes ] = useState([])
    const [ totalRoutes, setTotalRoutes ] = useState([])
    const [ spares, setSpares ] = useState([])
    let xData = ['23/04', '24/04', '25/04', '26/04', '27/04', '28/04', '29/04', '30/04', '31/04', '01/05', '02/05', '03/05', '04/05', '05/05'];
    const [ logCheck, setLogCheck ] = useState(true)

    useEffect(()=>{
        UserService.getDepotList().then(
            response =>{
                let data = []
                let res = response.data
                res?.map(resRow =>{
                    data.push({value: resRow.depots, label: resRow.depots})
                })
                setDepotOption(data)
            }
        ).catch(err =>{
          if(err.message == "Request failed with status code 403"){
              setLogCheck(false)
          }
        })
    },[])

    const handleStation = (e) =>{
        UserService.getStatisticData({location: e.value}).then(
            response =>{
                let ress = response.data
                let data = []
                ress?.map(row =>{
                    data.push({id: row.entry, updatedBy: row.updatedBy, type: row.type, location: row.location, week_no: row.week_no, date: row.date, total_routes: row.total_routes, depot_routes: row.depot_routes, volume_share: row.volume_share, tomorrows_routes: row.tomorrows_routes, das_available: row.das_available,  under_over: row.under_over, notes: row.notes})
                })
                console.log(response.data)
                setRows(data.reverse())
            }
        )
    }
    useEffect(()=>{
        if(!rows){
            setLoading(true)
        }else{
            setLoading(false)
            let xxData = []
            let shaData = []
            let depRoutes = []
            let totRoutes = []
            let spa = []
            for(let i = 0; i <= 14; i++){
                if(rows[14-i]?.date == rows[15-i]?.date){
                    xxData.push(rows[14-i]?.date?.split('-').length > 1 ? rows[14-i]?.date?.split('-')[2]+'/'+rows[14-i]?.date?.split('-')[1]+' 2nd' : rows[14-i]?.date?.split('/').length > 1 ? rows[14-i]?.date?.split('/')[0]+'/'+rows[14-i]?.date?.split('/')[1] +' 2nd':'')
                }else{
                    xxData.push(rows[14-i]?.date?.split('-').length > 1 ? rows[14-i]?.date?.split('-')[2]+'/'+rows[14-i]?.date?.split('-')[1] : rows[14-i]?.date?.split('/').length > 1 ? rows[14-i]?.date?.split('/')[0]+'/'+rows[14-i]?.date?.split('/')[1] :'')
                }
                    shaData.push(rows[14-i]?.volume_share)
                    depRoutes.push(rows[14-i]?.depot_routes)
                    totRoutes.push(rows[14-i]?.total_routes)
                    spa.push(rows[14-i]?.under_over)
            }
            console.log(shaData)
            setShareData(shaData)
            setXxxData(xxData)
            setDepotRoutes(depRoutes)
            setTotalRoutes(totRoutes)
            setSpares(spa)
        }
    },[rows])
    return (
        <div className="dashArch">
          {logCheck ?
          <>
            <h1 className="h1Profile">Daily Update Statistics</h1>
            <div className="flex chartsContainer">
                <LineChart
                    xAxis={[{ data: xxxData.length > 0 ? xxxData : xData , scaleType: 'point' }]}
                    series={[
                    {
                        label: '% share', data: shareData.length > 0 ? shareData : xData,
                    },
                    {
                        label: 'Spares', data: spares.length > 0 ? spares : xData,
                    },
                    ]}
                    height={350}
                    width={800}
                    margin={{ left: 30, right: 30, top: 30, bottom: 50 }}
                    grid={{ vertical: true, horizontal: true }}
                    sx={{
                        [`& .${areaElementClasses.root}`]: {
                          fill: 'url(#swich-color-id-2)',
                        },
                      }}
                    >
                    <ColorPalette id="swich-color-id-2" />
                    <rect x={0} y={0} width={5} height="100%" fill="url(#swich-color-id-2)" />
                    </LineChart>
                
                <LineChart
                    xAxis={[{ data: xxxData.length > 0  ? xxxData : xData, scaleType: 'point' }]}
                    series={[
                        {
                            label: 'Depot Routes' ,data: depotRoutes.length > 0 ? depotRoutes : xData,
                        },
                        {
                            label: 'Total Routes' ,data: totalRoutes.length > 0 ? totalRoutes: xData,
                        },
                    
                    ]}
                    height={370}
                    width={800}
                    margin={{ left: 30, right: 30, top: 30, bottom: 70 }}
                    grid={{ vertical: true, horizontal: true }}
                />
            </div>
            <div className="selectStatistics">
                <label for="complete" className="label2">Station</label>
                <Select 
                        options={depotOption}
                        styles={colourStyles}
                        //value={{label: status, value: status}}
                        onChange={e => handleStation(e)}
                /> 
            </div>
            <Box sx={{ height: 550, width: 1600, paddingLeft: '0%', marginTop: '20px' }}>
                <DataGridPro
                
                    // checkboxSelection
                    //onRowSelectionModelChange={params => setSelected(params)}
                    rows={rows}
                    
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '20px', fontFamily: ''}}
                    pageSizeOptions={[100]}
                    //onRowClick={params => handleRow(params)}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: GridToolbar
                    }}
                    loading={loading}
                    //{...data}
                    //processRowUpdate={handleCellEdit}
                    //onCellClick={handleClick}
            
                />
            </Box>
          </>:
          <>
              <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
          </>
          }
        </div>
    );
  }
  export default Statistics;