import React from 'react';
import { useState, useEffect } from 'react';
import UserService from '../../services/user.service.js';
import picture from './img/placeholder.jpg';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl, { formControlClasses } from '@mui/material/FormControl';
import LinearWithValueLabel from './progress.component.js';
import nationalities from './nationalities.js';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Select, { useStateManager } from 'react-select';
import countries from './countryes.js';
import Calendar from 'react-calendar';
import axios from 'axios';



const colourStyles = {
    control: styles => ({ ...styles, border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)' , width: '100%', height: '50px', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
        ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
        }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 10,
        backgroundColor: '#41a3a3',
        ...theme.applyStyles('dark', {
        backgroundColor: '#308fe8',
        }),
    },
}));

const cariers = [
    {value:'Amazon' , label:'Amazon' },
    {value:'DPD' , label:'DPD' },
    {value:'DX' , label:'DX' },
    {value:'Fedex' , label:'Fedex' },
    {value:'Hermes' , label:'Hermes' },
    {value:'TNT' , label:'TNT' },
    {value:'UPS' , label:'UPS' },
    {value:'Royal Mail' , label:'Royal Mail' },
    {value:'Yodel' , label:'Yodel' },
]

const reffered = [
    {value: 'Dan Dewey', label: 'Dan Dewey'},
    {value: 'George Willis', label: 'George Willis'},
    {value: 'Hannah Jackson', label: 'Hannah Jackson'},
    {value: 'Maddison Cross', label: 'Maddison Cross'}
]

const lastOption = [
    {value:'Google' , label:'Google'},
    {value:'Indeed' , label:'Indeed'},
    {value:'Recommendation by Friend' , label:'Recommendation by Friend'},
    {value:'Facebook' , label:'Facebook'},
    {value:'Van Sign' , label:'Van Sign'},
    {value:'Other' , label:'Other'},
]


const AdForm = (props) =>{
    const [ steps, setSteps ] = useState(1)
    const [ rental, setRental ] = useState()
    const [ en, setEn ] = useState('Yes')
    const [ fullTime, setFullTime ] = useState('Yes')
    const [ manual, setManual ] = useState('Yes')
    const [ oneYear, setOneYear ] = useState('Yes')
    const [ six, setSix ] = useState('No')
    const [ endo, setEndo ] = useState('No')
    const [ dna, setDna ] = useState('Yes')
    const [ driveCon, setDriveCon ] = useState('Yes')
    const [ waringRental, setWarningRental ] = useState()
    const [ stations, setStations ] = useState()
    const [ station, setStation ] = useState()
    const [ nat, setNat ] = useState()
    const [ value, setValue ] = useState()
    const [ car, setCar ] = useState()
    const [ rec, setRec ] = useState('No')
    const [ first, setFirst ] = useState()
    const [ last, setLast ] = useState()
    const [ address1, setAddress1 ] = useState()
    const [ address2, setAddress2 ] = useState()
    const [ town, setTown ] = useState()
    const [ state, setState ] = useState()
    const [ postCode, setPostCode ] = useState()
    const [ phone, setPhone ] = useState()
    const [ email, setEmail ] = useState()
    const [ confirm, setConfirm ] = useState()
    const [ nationality, setNationality ] = useState()
    const [ dob, setDob ] = useState()
    const [ age, setAge ] = useState()
    const [ nino, setNino ] = useState()
    const [ dlNo, setDlNo ] = useState()
    const [ dlExp, setDlExp ] = useState()
    const [ points, setPoints ] = useState()
    const [ shareCode, setShareCode ] = useState()
    const [ dlOrigin, setDlOrigin ] = useState()
    const [ experience, setExperience ] = useState()
    const [ refferal, setRefferal ] = useState()
    const [ warningFirst, setWarningFirst ] = useState()
    const [ warningLast, setWarningLast ] = useState()
    const [ warningAddress1, setWarningAddress1 ] = useState()
    const [ warningAddress2, setWarningAddress2 ] = useState()
    const [ warningTown, setWarningTown ] = useState()
    const [ warningState, setWarningState ] = useState()
    const [ warningPostCode, setWarningPostCode ] = useState()
    const [ warningPhone, setWarningPhone ] = useState()
    const [ warningEmail, setWarningEmail ] = useState()
    const [ warningConfirm, setWarningConfirm ] = useState()
    const [ warningNationality, setWarningNationality ] = useState()
    const [ warningDob, setWarningDob ] = useState()
    const [ warningDlOrigin, setWarningDlOrigin ] = useState()
    const [ warningExperience, setWarningExperience ] = useState()
    const [ warningRefferal, setWarningRefferal ] = useState()
    const [ interviews, setInterviews ] = useState([])
    const [ buttons, setButtons ] = useState([])
    const [ date, setDate ] = useState()
    const [ time, setTime ] = useState()
    const [ warningDate, setWarningDate ] = useState()
    const [ warningTime, setWarningTime ] = useState()
    const [ checked, setChecked ] = useState(false)
    const [ optIn, setOptIn ] = useState('Yes')
    const [ extraButtons, setExtraButtons ] = useState([])
    const [ lead, setLead ] = useState()
    const [ warningLead, setWarningLead ] = useState()
    const [ addresses, setAddresses ] = useState([])
    const [ addressOption, setAddressOption ] = useState([])
    const [ selectedAddress, setSelectedAddress ] = useState()
    let depot = ''

    if(props.depot){
        depot = props.depot
    }else{
        depot = new URLSearchParams(window.location.search).get('depot')
    }

    useEffect(()=>{
        UserService.getStations().then(
            response =>{
                let data = response.data
                data.map(row =>{
                    if(depot == row.station_code){
                        setStation(row)
                        console.log(row)
                    }
                })
            }
        )
        let data = []
        nationalities?.map(row =>{
            data.push({value: row.value, label: row.value})
        })
        setNat(data)
        let dat = {station: depot}
        UserService.getInterviews(dat).then(
            response => {
                console.log(response.data)
                setInterviews(response.data)
            }
        ).catch(err => console.log(err))
    },[])

    useEffect(()=>{
        setAge(Math.floor((new Date() - new Date(dob).getTime())/31556926000))
    },[dob])

    useEffect(()=>{
        if(rental){
            setWarningRental("")
        }
    },[rental])

    useEffect(()=>{
        if(first){
            setWarningFirst("")
        }
        if(last){
            setWarningLast("")
        }
        if(address1){
            setWarningAddress1("")
        }
        if(address2){
            setWarningAddress2("")
        }
        if(town){
            setWarningTown("")
        }
        if(state){
            setWarningState("")
        }
        if(postCode){
            setWarningPostCode("")
        }
        if(phone){
            setWarningPhone("")
        }
        if(email){
            setWarningEmail("")
        }
        if(nationality){
            setWarningNationality("")
        }
        if(dob){
            setWarningDob("")
        }
        if(dlOrigin){
            setWarningDlOrigin("")
        }
        if(car){
            setWarningExperience("")
        }
       
        if(confirm && confirm !== email){
            setWarningConfirm("has to match email")
        }else{
            setWarningConfirm("")
        }
    },[first, last, address1, address2, town, state, postCode, phone, email, confirm, nationality, dob , dlOrigin, car, rec])

    useEffect(()=>{
        if(lead){
            setWarningLead("")
        }
    },[lead])

    useEffect(()=>{
        if(date){
            setWarningDate("")
        }
        if(time){
            setWarningTime("")
        }
    },[date, time])
        

    const next = () =>{
        if(rental){
            setSteps(steps+1)
        }else{
            setWarningRental("this field is required")
        }
    }
    const previous = () =>{
        setSteps(steps-1)
    }
    const next1 = () =>{
        if(first && last && address1 && address2 && town && state && postCode && phone && email && confirm && nationality && dob  && dlOrigin && car && rec){
            setSteps(steps+1)
        }else{
            if(!first){
                setWarningFirst("field required")
            }
            if(!last){
                setWarningLast("field required")
            }
            if(!address1){
                setWarningAddress1("field required")
            }
            if(!address2){
                setWarningAddress2("field required")
            }
            if(!town){
                setWarningTown("field required")
            }
            if(!state){
                setWarningState("field required")
            }
            if(!postCode){
                setWarningPostCode("field required")
            }
            if(!phone){
                setWarningPhone("field required")
            }
            if(!email){
                setWarningEmail("field required")
            }
            if(!confirm){
                setWarningConfirm("field required")
            }
            if(!nationality){
                setWarningNationality("field required")
            }
            if(!dob){
                setWarningDob("field required")
            }
            if(!dlOrigin){
                setWarningDlOrigin("field required")
            }
            if(!car){
                setWarningExperience("field required")
            }
            if(!rec){
                setWarningRefferal("field required")
            }
        }
    }
    const next2 = () =>{
        if(date && time){
            setSteps(steps+1)
        }else{
            if(!date){
                setWarningDate("field required")
            }
            if(!time){
                setWarningTime("field required")
            }
        }
    }
    
    const handleDate = (ex) =>{
        let data = []
        let date = ex
        interviews.map((interview, index) =>{
            if(new Date(interview.date).getDate() == new Date(ex).getDate() && new Date(interview.date).getMonth() == new Date(ex).getMonth() && new Date(interview.date).getFullYear() == new Date(ex).getFullYear() && Number(interview.amount) > 0){
                setDate(interview.date)
                data.push(
                    <div className='slotBtt'>
                        <button key={index} value={index} onClick={e => handleButton(e, date, index, interview)} className='slotBtt'>{interview.time}</button><br></br>
                    </div>
                )
            }
        })
        setExtraButtons(data)
        setButtons(data)
    }
    const handleButton = (e, ex, indexX, interview) =>{
        console.log(extraButtons)
        console.log(ex)
        let data = []
        setTime(interview?.time)
        interviews.map((interview1, index) =>{
            if(indexX == index && Number(interview1.amount) > 0 ){
                data.push(
                    <div className='slotBtt'> 
                        <button key={index} vlaue={index} style={{backgroundColor:'#40b1ab', color:'white'}} className='slotBtt' >{interview1.time + ' - Selected'}</button><br></br>
                    </div>
                    )
            }else if(interview1?.time !== interview?.time && new Date(interview1.date).getDate() == new Date(ex).getDate() && new Date(interview1.date).getMonth() == new Date(ex).getMonth() && new Date(interview1.date).getFullYear() == new Date(ex).getFullYear() && Number(interview1.amount) > 0){
                data.push(
                    <div className='slotBtt'>
                        <button key={index} value={index} onClick={e => handleButton(e, ex, index, interview1)} className='slotBtt'>{interview1.time}</button><br></br>
                    </div>
                )
            }
        })   
        setButtons(data)

    }
    const handleLead = (param) =>{
        setLead(param)
    }
    
    const handleSubmit = () =>{
        if(lead){
            const myRnId = parseInt(Math.floor(Math.random() * ( 999999 - 100000 + Math.random() * 100) + 100000))
            const data =  {
                account_no: rental == 'Yes' ? 'SK'+myRnId : 'SO'+myRnId,
                name: first+' '+last, 
                station: depot, 
                skso_role: 'DA', 
                first_name: first, 
                last_name: last, 
                phone: phone, 
                email_skso: 'sk'+myRnId+'@skso.co.uk', 
                email: email, 
                address1: address1, 
                address2: address2, 
                town: town, 
                county: state, 
                post_code: postCode,
                nationality: nationality, 
                active_pay_cycle: '2',
                dob: dob,
                age: age,
                nino: nino,
                vehicle_source: rental == 'Yes' ? 'Rental' : 'Owner Driver',
                dl_no: dlNo,
                dl_expiry: dlExp,
                dl_points: points,
                dl_endorsements: endo,
                dl_sharecode: shareCode,
                apply_interview_date: date,
                apply_interview_time: time,
                apply_lead: refferal,
                //apply_onboarding_skso_acc: '',
                apply_previous_exp: experience,
                //apply_referral : '',
                apply_source: lead,
                gdpr_whatsapp: optIn,
                status: 'Pending',
                contact: station.contact,
                contact_phone: station.phone,
                station_address1: station.address1,
                station_address2: station.address2,
                station_town: station.town,
                station_county: station.county,
                station_post: station.postcode,
            }
            UserService.apply(data).then(
                response =>{
                    if(response.status == 200){
                        setSteps(steps+1)
                        console.log(response)
                    }
                }
            ).catch(err => console.log(err.message))
        }else{
                setWarningLead('filed required')
        }
    }

    const handleLookup = () =>{
        const link = `https://api.getAddress.io/find/${postCode}?api-key=N9JcEsWTfEWbpH76tKfwqg44323`
        axios.get(link).then(
            result =>{
                setAddresses(result.data.addresses)
            }
        )
    }

    useEffect(()=>{
        console.log(addresses)
        let data = []
        addresses?.map(address =>{
            
            const filterAdd = address.split(',').filter((item) => item !== " ")
            console.log(filterAdd)
            let addressPlc = ''
            if(filterAdd.length == 3){
                addressPlc = filterAdd[0]+', '+filterAdd[1]+', '+filterAdd[2]
            }else if(filterAdd.length == 4){
                addressPlc = filterAdd[0]+', '+filterAdd[1]+', '+filterAdd[2]+', '+filterAdd[3]
            }else if(filterAdd.length == 5){
                addressPlc = filterAdd[0]+' '+filterAdd[1]+', '+filterAdd[2]+', '+filterAdd[3]+', '+filterAdd[4]
            }else if(filterAdd.length == 6){
                addressPlc = filterAdd[0]+' '+filterAdd[1]+' '+filterAdd[2]+', '+filterAdd[3]+', '+filterAdd[4]+', '+filterAdd[5]
            }else if(filterAdd.length == 2){
                addressPlc = filterAdd[0]+', '+filterAdd[1]
            }

            data.push({value: addressPlc, label: addressPlc})
        
            
        })
        setAddressOption(data)
    },[addresses])

    const handleSelectedAddress = (value) =>{
        setSelectedAddress({value: value, label: value})
        addressOption?.map(row =>{
            if(row.value == value){
                let addressArray = value.split(',')
                if(addressArray.length == 3){
                    setAddress1(addressArray[0])
                    setAddress2("")
                    setTown(addressArray[1])
                    setState(addressArray[2])
                }else if(addressArray.length == 2){
                    setAddress1(addressArray[0])
                    setAddress2("")
                    setTown(addressArray[1])
                    setState(addressArray[1])
                }else{
                    setAddress1(addressArray[0])
                    setAddress2(addressArray[1])
                    setTown(addressArray[2])
                    setState(addressArray[3])
                }
            }
        })
    }
    

    return(
        <div className='correctionAdd dashArch'>
            <div className='pictureHeader'>
                <img src={picture} className='pictureH'/>
                <h1  className='applicationTitle'>Application Form</h1><br></br>
                <h4 style={{color: 'white', textAlign:'center'}}>Join Our Team and Begin Your Fulfilling Career in Logistics with Us.</h4>
            </div>
            <div className='bodyForm'>
                <div className='flex' style={{paddingLeft : '12%', paddingTop: '3%'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                            m: 1,
                            width: 828,
                            height: 'fit-content',
                            },
                        }}
                    >
                        <Paper elevation={3} >
                            {steps == 1 ?
                            <div>
                                {props.depot ? <p className='depotApplicationFormTitle'>{props.depot}</p> :''}
                                <div className='loadingBar '>
                                    <BorderLinearProgress variant="determinate" value={20} /><p>20%</p>
                                </div>
                                <div className='plesaseNote'>
                                    <h5>Please Note</h5><br></br>
                                    <p className='mainP'>
                                        To continue you need to answer the next set of questions. Please be honest with your answers as this may fail your application later on.
                                    </p>
                                    <p className='mainP'>
                                        Remember if at any point you want to speak with an advisor you can use the chat icon in the corner and we will reply as soon as we can.
                                    </p>
                                </div>
                                <div className='questions'>
                                    <h5 className='que'>Do you own your own commercial vehicle or do you need to rent one? <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            //defaultValue="Yes"
                                            name="radio-buttons-group"
                                            onChange={e => setRental(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Use my Own Van" />
                                                <FormControlLabel value="Yes" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="I Need to Rent a Van" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                    <p className='queP'><i>If you need to rent a vehicle one of our partners will be happy to help</i></p>
                                    {waringRental  ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{waringRental}</p> : ''
                                    }
                                </div>:
                                { fullTime == 'Yes' && manual == 'Yes' && oneYear == 'Yes' && six == 'No' && endo == 'No' && dna == 'Yes' && driveCon == 'Yes' ?
                                <div className='questions'>
                                    <h5 className='que'>You have a basic understanding of the written and spoken English language <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Yes"
                                            name="radio-buttons-group"
                                            onChange={e => setEn(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div> :''
                                }
                                {en == 'Yes' && manual == 'Yes' && oneYear == 'Yes' && six == 'No' && endo == 'No' && dna == 'Yes' && driveCon == 'Yes' ?
                                <div className='questions'>
                                    <h5 className='que'>Your interested in full time work, including weekends <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Yes"
                                            name="radio-buttons-group"
                                            onChange={e => setFullTime(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div> :''
                                }
                                {en == 'Yes' && fullTime == 'Yes' && oneYear == 'Yes' && six == 'No' && endo == 'No' && dna == 'Yes' && driveCon == 'Yes' ?
                                <div className='questions'>
                                    <h5 className='que'>You can drive a manual vehicle, not automatic <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Yes"
                                            name="radio-buttons-group"
                                            onChange={e => setManual(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>:''
                                }
                                {en == 'Yes' && fullTime == 'Yes' && manual == 'Yes' && six == 'No' && endo == 'No' && dna == 'Yes' && driveCon == 'Yes' ?
                                <div className='questions'>
                                    <h5 className='que'>You have a Full UK or EU Licence held for more than 1 year <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Yes"
                                            name="radio-buttons-group"
                                            onChange={e => setOneYear(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>:''
                                }
                                {en == 'Yes' && fullTime == 'Yes' && manual == 'Yes' && oneYear == 'Yes' && endo == 'No' && dna == 'Yes' && driveCon == 'Yes' ?
                                <div className='questions'>
                                    <h5 className='que'>Do you have more than 6 points on your UK driving licence <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="No"
                                            name="radio-buttons-group"
                                            onChange={e => setSix(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>:''
                                }
                                {en == 'Yes' && fullTime == 'Yes' && manual == 'Yes' && oneYear == 'Yes' && six == 'No' && dna == 'Yes' && driveCon == 'Yes' ?
                                <div className='questions'>
                                    <h5 className='que'>Do you have any CD,DD,DR,IN,MS10,UT50 or TT Endorsements on your driving record <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="No"
                                            name="radio-buttons-group"
                                            onChange={e => setEndo(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>:''
                                }
                                {en == 'Yes' && fullTime == 'Yes' && manual == 'Yes' && oneYear == 'Yes' && six == 'No' && endo == 'No' && driveCon == 'Yes' ?
                                <div className='questions'>
                                    <h5 className='que'>Happy to complete a Drugs & Alcohol screening and a background check <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Yes"
                                            name="radio-buttons-group"
                                            onChange={e => setDna(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div> :''
                                }
                                {en == 'Yes' && fullTime == 'Yes' && manual == 'Yes' && oneYear == 'Yes' && six == 'No' && endo == 'No' && dna == 'Yes' ?
                                <div className='questions'>
                                    <h5 className='que'>Confident to drive a commercial vehicle <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h5>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Yes"
                                            name="radio-buttons-group"
                                            onChange={e => setDriveCon(e.target.value)}
                                        >
                                            <div className='flex'>
                                                <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>:''
                                }
                                {en == 'Yes' && fullTime == 'Yes' && manual == 'Yes' && oneYear == 'Yes' && six == 'No' && endo == 'No' && dna == 'Yes' && driveCon == 'Yes' ?
                                <>
                                    <div className='plesaseNote' style={{marginBottom: '3%'}}>
                                        <h5>Next</h5><br></br>
                                        <p className='mainP'>
                                            Thank you for the information, on the next page we will collect some personal information for our records to assist with your onboarding should you be successful in your application.                                    
                                        </p>
                                        <p className='mainP'>
                                            Remember if at any point you want to stop and speak with an advisor you can message us using the chat icon and we will reply as soon as we can.                                    
                                        </p>
                                    </div>
                                    <div style={{textAlign: 'left', paddingLeft: '5%', paddingBottom: '5%'}}>
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={next}>
                                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Next</span>
                                            <div class="fill-container"></div>
                                        </button>
                                        {waringRental  ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>missing field</p> : ''
                                        }
                                    </div>
                                    
                                </>: 
                                <div className='plesaseNoteRed' style={{marginBottom: '3%'}}>
                                    <h5>Unfortunately</h5><br></br>
                                    <p className='mainP'>
                                        But this position is for full-time personnel with the availability to work weekends. If you can not meet the criteria set out by our client then we can not continue with your application.                                    
                                    </p>
                                    <p className='mainP'>
                                        Alternatively, if you believe this is incorrect please get in touch with us using the chat icon in the corner.     
                                    </p>
                                </div>
                                }
                            </div>: steps == 2?
                            <div>
                                {props.depot ? <p className='depotApplicationFormTitle'>{props.depot}</p> :''}
                                <div className='loadingBar ' style={{paddingBottom: '3%', borderBottom: '1px solid white'}}>
                                    <BorderLinearProgress variant="determinate" value={40} /><p>40%</p>
                                </div>
                                <div className='tables'>
                                    {rental == 'No' ? <h5><b>If you have your own large size van</b></h5> : <h5><b>If you need to rent your large size van <sup style={{fontSize: '14px', paddingBottom: '20px'}}>(rental is £{station?.vanrate2.toFixed(2)} per week)</sup></b></h5> }
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='tableTh'><h5><b>Daily Rate</b></h5></th>
                                                <th className='tableTh'><h5><b>Working 5 Day's</b></h5></th>
                                                <th className='tableTh'><h5><b>Working 6 Day's</b></h5></th>
                                                <th className='tableTh'><h5><b>Hourly Rate</b></h5></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='tableTd'>£{rental == 'No' ? station?.rate2.toFixed(2) : station?.rate2.toFixed(2)}</td>
                                                <td className='tableTd'>£{rental == 'No' ? station?.rate2*5 : station?.rate2*5+station?.vanrate2}</td>
                                                <td className='tableTd'>£{rental == 'No' ? station?.rate2*6 : station?.rate2*6+station?.vanrate2}</td>
                                                <td className='tableTd'>£{rental == 'No' ? (station?.rate2/9).toFixed(2) : ((station?.rate2+(station?.vanrate2/5))/9).toFixed(2)+' (Minimum)'} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {rental  == 'Yes' ? <p>*£500 Deposit required (collected over 5 weeks and returned 28days after vehicle returned)</p> : ''}
                                </div>
                                <div className='tables'>
                                    {rental == 'No' ? <h5><b>If you have your own standard size van</b></h5> : <h5><b>If you need to rent your standard size van <sup style={{fontSize: '14px', paddingBottom: '20px'}}>(rental is £{station?.vanrate1.toFixed(2)} per week)</sup></b></h5>}
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='tableTh'><h5><b>Daily Rate</b></h5></th>
                                                <th className='tableTh'><h5><b>Working 5 Day's</b></h5></th>
                                                <th className='tableTh'><h5><b>Working 6 Day's</b></h5></th>
                                                <th className='tableTh'><h5><b>Hourly Rate</b></h5></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='tableTd'>£{rental == 'No' ? station?.rate1.toFixed(2) : station?.rate1.toFixed(2)}</td>
                                                <td className='tableTd'>£{rental == 'No' ? station?.rate1*5 : station?.rate1*5+station?.vanrate1}</td>
                                                <td className='tableTd'>£{rental == 'No' ? station?.rate1*6 : station?.rate1*6+station?.vanrate1}</td>
                                                <td className='tableTd'>£{rental == 'No' ? (station?.rate1/9).toFixed(2) : ((station?.rate1+(station?.vanrate1/5))/9).toFixed(2)+' (Minimum)'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {rental  == 'Yes' ? <p>*£500 Deposit required (collected over 5 weeks and returned 28days after vehicle returned)</p> : ''}
                                </div>
                                <div className='extra'>
                                    <h5 ><b>When</b></h5>
                                    <p className='mainP' style={{marginTop: '-5px'}}>
                                        {station.incentive_notes}                                    
                                    </p>
                                </div>
                                <div className='extra'>
                                    <h5 ><b>Incentives</b></h5>
                                    <p className='mainP' style={{marginTop: '-5px'}}>
                                        £{station?.incentive} per day bonus, this is an extra £{station?.incentive*5} per week for 5 days worked or an extra £{station?.incentive*6} for every 6 days worked.                                 
                                    </p>
                                </div>
                                <div style={{textAlign: 'left', paddingLeft: '5%', paddingBottom: '5%', display: 'flex'}}>
                                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={previous}>
                                        <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Previous</span>
                                        <div class="fill-container"></div>
                                    </button>
                                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={next}>
                                        <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Next</span>
                                        <div class="fill-container"></div>
                                    </button>
                                </div>
                            </div>: steps == 3 ?
                            <div>
                                {props.depot ? <p className='depotApplicationFormTitle'>{props.depot}</p> :''}
                                <div className='loadingBar ' style={{paddingBottom: '3%', borderBottom: '1px solid white'}}>
                                    <BorderLinearProgress variant="determinate" value={60} /><p>60%</p>
                                </div>
                                <div className='extra'>
                                    <p className='mainP' style={{marginTop: '-5px'}}>
                                        To continue your application please complete the form below or if you would like to have a chat with us before completing the application please use the chat button in the corner                                   
                                    </p>
                                </div>
                                <div className='extra'>
                                    <h4 className='que'>Name <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                    <div className='flex' style={{marginTop: '-1%'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            <p className='formP'>First</p>
                                            <input type="text" className="inputEdit" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={first ? first : ''} onChange={e => setFirst(e.target.value)}></input>
                                        </div>
                                        <div className="flexComp addFormCorrectionLeft">
                                            <p className='formP'>Last</p>
                                            <input type="text" className="inputEdit " style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={last ? last :''} onChange={e => setLast(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className='flex' style={{marginTop: '-4.5%', textAlign: 'right'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            {warningFirst  ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningFirst}</p> : ''
                                            }
                                        </div>
                                        <div className="flexComp">
                                            {warningLast ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningLast}</p> : ''
                                            }
                                        </div>
                                    </div>
                                    <h4 className='que' style={{marginTop: '5%'}}>Address <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                    <div className='flex' style={{marginTop: '3%'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            <p className='formP'>Postcode</p>
                                            <input type="text" className="inputEdit" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={postCode ? postCode : ''} onChange={e => setPostCode(e.target.value.toUpperCase())} ></input>
                                        </div>
                                        <div className="flexComp">
                                        </div>
                                    </div>
                                    <div className='flex' style={{marginTop: '-4.5%', textAlign: 'right'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            {warningPostCode  ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningPostCode}</p> : ''
                                            }
                                        </div>
                                        <div className="flexComp">
                                        </div>
                                    </div>                                                                                                                                                                                                                                                                                                      {/*- implementing          -------------------------------------------------------------------*/}
                                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#2596be'}} onClick={handleLookup}>
                                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Lookup</span>
                                        <div class="fill-container"></div>
                                    </button>
                                    {addressOption.length > 0 ?
                                    <div className="flex" style={{marginTop: '-1%'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            <p className='formP'>Select Address</p>
                                            <Select 
                                                options={addressOption}
                                                styles={colourStyles}
                                                //value={{label: station, value: station}}
                                                onChange={e => handleSelectedAddress(e.value)}
                                            />      
                                        </div>
                                    </div>:''
                                    }
                                    <div className="flex" style={{marginTop: '-2%'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                                <p className="formP">Street Address</p>
                                                <input type="text"  className="lone loneCorrection" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={address1 ? address1 : ''} onChange={e => setAddress1(e.target.value)} ></input>
                                        </div>
                                    </div>
                                    {warningAddress1  ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '-2%'}}>{warningAddress1}</p> : ''
                                    }
                                    <div className="flex" style={{marginTop: '-2%'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                                <p className="formP">Address Line 2</p>
                                                <input type="text"  className="lone loneCorrection" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={address2 ? address2 : ''} onChange={e => setAddress2(e.target.value)} ></input>
                                        </div>
                                    </div>
                                    {warningAddress2  ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '-2%'}}>{warningAddress2}</p> : ''
                                    }
                                    <div className='flex' style={{marginTop: '-1%'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            <p className='formP'>Town/City</p>
                                            <input type="text" className="inputEdit" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={town ? town : ''} onChange={e => setTown(e.target.value)} ></input>
                                        </div>
                                        <div className="flexComp addFormCorrectionLeft">
                                            <p className='formP'>State</p>
                                            <input type="text" className="inputEdit " style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={state ? state :''} onChange={e => setState(e.target.value)} ></input>
                                        </div>
                                    </div>
                                    <div className='flex' style={{marginTop: '-4.5%', textAlign: 'right'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            {warningTown  ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningTown}</p> : ''
                                            }
                                        </div>
                                        <div className="flexComp">
                                            {warningState ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningState}</p> : ''
                                            }
                                        </div>
                                    </div>
                                    
                                    
                                    <div style={{paddingTop: '7%'}}>
                                        <h4 className='que'>Phone <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                        <PhoneInput
                                            countryCallingCodeEditable={false}
                                            placeholder="Enter phone number"
                                            country="GB"
                                            defaultCountry="GB"
                                            value={value}
                                            international
                                            withCountryCallingCode
                                            onChange={setPhone}
                                    
                                        />
                                    </div>
                                    {warningPhone  ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '-0.5%'}}>{warningPhone}</p> : ''
                                    }
                                    <div style={{paddingTop: '3%'}}>
                                        <h4 className='que'>Email <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                        <div className='flex' style={{marginTop: '1%'}}>
                                            <div className="flexComp addFormCorrectionTop" style={{marginLeft: '0px'}}>
                                                <input type="text" className="inputEdit" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={email ? email : ''} onChange={e => setEmail(e.target.value)} ></input>
                                                <p className='formPX'>Enter Email</p>
                                            </div>
                                            <div className="flexComp addFormCorrectionLeft addFormCorrectionTop">
                                                <input type="text" className="inputEdit " style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={confirm ? confirm :''} onChange={e => setConfirm(e.target.value)} ></input>
                                                <p className='formPX'>Confirm Email</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex' style={{marginTop: '-6.2%', textAlign: 'right'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            {warningEmail  ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningEmail}</p> : ''
                                            }
                                        </div>
                                        <div className="flexComp">
                                            {warningConfirm ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningConfirm}</p> : ''
                                            }
                                        </div>
                                    </div>
                                    <div style={{paddingTop: '10%'}}>
                                        <h4 className='que'>Nationality <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                        <p className='mainPX' >
                                            Although we ask for nationality you are under no obligation to answer if you would rather not answer please select <b>Prefer Not to Say</b>. Your answer has no impact on your application provided you have the right to work in the UK                                   
                                        </p>
                                        <Select 
                                            options={nat}
                                            styles={colourStyles}
                                            //value={{label: station, value: station}}
                                            onChange={e => setNationality(e.value)}
                                        />     
                                    </div>
                                    {warningNationality ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '-0.5%'}}>{warningNationality}</p> : ''
                                    }
                                    <div className='flex ' style={{marginTop: '2%'}}>
                                        <div className="flexComp addFormCorrectionTop" style={{marginLeft: '0px'}}>
                                            <h4 className='que'>Date Of Birth <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                            <input type="date" className="inputEdit" style={{height: '50px', marginTop: '1%', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={dob ? dob : ''} onChange={e => setDob(e.target.value)}></input>
                                    
                                        </div>
                                        <div className="flexComp addFormCorrectionLeft">
                                            <h4 className='que'>Age</h4>
                                            <input type="text" className="inputEdit " style={{height: '50px', marginTop: '1%' , border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={age ? age :''}  disabled></input>
                                        </div>
                                    </div>
                                    <div className='flex' style={{marginTop: '-4.5%', textAlign: 'right'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            {warningDob  ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningDob}</p> : ''
                                            }
                                        </div>
                                        <div className="flexComp">
                                        </div>
                                    </div>
                                </div>
                                <div className='extra' style={{paddingTop:'5%', borderTop: '1px solid rgb(201, 201, 201)'}}>
                                    <div className="flex" style={{marginTop: '-2%'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                                <h4>Do you know your National Insurance No</h4>
                                                <p>If you do please enter it, if not leave the answer blank</p>
                                                <input type="text"  className="lone loneCorrection" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={nino ? nino : ''} onChange={e => setNino(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="flex" style={{marginTop: '2%'}}>
                                        <div className="flexComp" style={{marginLeft: '0px'}}>
                                            <h4>Do you know your driving licence details</h4>
                                            <p>If you do please enter it, if not leave the answer blank</p>
                                            <div className='flex' style={{marginTop: '-1%'}}>
                                                <div className="flexComp" style={{marginLeft: '0px'}}>
                                                    <h4>Licence No</h4>
                                                    <input type="text" className="inputEdit" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={dlNo ? dlNo : ''} onChange={e => setDlNo(e.target.value)} ></input>
                                                </div>
                                                <div className="flexComp addFormCorrectionLeft addFormCorrectionTop">
                                                    <h4>Expiry Date</h4>
                                                    <input type="date" className="inputEdit " style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={dlExp ? dlExp :''} onChange={e => setDlExp(e.target.value)} ></input>
                                                </div>
                                            </div>
                                            <div className='flex' style={{marginTop: '1%'}}>
                                                <div className="flexComp addFormCorrectionTop" style={{marginLeft: '0px'}}>
                                                    <h4>Points on Licence</h4>
                                                    <input type="number" className="inputEdit" style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={points ? points : ''} onChange={e => setPoints(e.target.value)} ></input>
                                                </div>
                                                <div className="flexComp addFormCorrectionLeft addFormCorrectionTop">
                                                    <h4>Licence Share Code</h4>
                                                    <input type="text" className="inputEdit " style={{height: '50px', border: '2px solid rgb(218, 218, 218)', backgroundColor: 'rgb(248, 248, 248)'}} value={shareCode ? shareCode :''} onChange={e => setShareCode(e.target.value)} ></input>
                                                </div>
                                            </div>
                                            <div className='flex' style={{marginTop: '1%'}}>
                                                <div className="flexComp" style={{marginLeft: '0px'}}>
                                                    
                                                </div>
                                                <div className="flexComp">
                                                    <p>To support your application, you can provide a licence share code from the DVLA using this link <a target="_blank" href='https://www.gov.uk/view-driving-licence' id='jobsLink'>Get Share Code</a> which will open a new page.</p>
                                                </div>
                                            </div>
                                            <div className="flex" style={{marginTop: '-1%'}}>
                                                <div className="flexComp" style={{marginLeft: '0px'}}>
                                                    <h4 className='que'>Which country was your Licence Issued in <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                                    <Select 
                                                        options={countries}
                                                        styles={colourStyles}
                                                        //value={{label: station, value: station}}
                                                        onChange={e => setDlOrigin(e.value)}
                                                    />      
                                                </div>
                                            </div>
                                            {warningDlOrigin ? 
                                                <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '-0.5%'}}>{warningDlOrigin}</p> : ''
                                            } 
                                        </div>
                                    </div>
                                </div>
                                <div className='extra' style={{marginTop: '-3%', borderBottom: '0px'}}>
                                    <div >
                                        <h4 className='que'>Do you have any experience with Multi Drop Delivery work? <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                //defaultValue="Yes"
                                                name="radio-buttons-group"
                                                onChange={e => setCar(e.target.value)}
                                            >
                                                <div className='flex'>
                                                    <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                    <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {warningExperience ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '-6%'}}>{warningExperience}</p> : ''
                                    } 
                                    {car == 'Yes' ?
                                        <div>
                                            <h4 className='que'>Any of these Carriers <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                            <Select
                                                //defaultValue={[colourOptions[2], colourOptions[3]]}
                                                isMulti
                                                name="colors"
                                                options={cariers}
                                                styles={colourStyles}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={e => {
                                                    let data = ''
                                                    e?.map((row, index) =>{
                                                        if(index == 0){
                                                            data = row.value
                                                        }else{
                                                            data += ', '+row.value
                                                        }   
                                                    })
                                                    
                                                    setExperience(data)
                                                }}
                                            />
                                            <p>You can select multiple options</p>
                                        </div> :''
                                    }
                                    <div >
                                        <h4 className='que'>Did someone recommend this advertisement? <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="No"
                                                name="radio-buttons-group"
                                                onChange={e => setRec(e.target.value)}
                                            >
                                                <div className='flex'>
                                                    <FormControlLabel value="No" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                    <FormControlLabel value="Yes" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {rec == 'Yes' ?
                                        <div>
                                            <h4 className='que'>Who referred you? <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                            <Select 
                                                options={reffered}
                                                styles={colourStyles}
                                                //value={{label: station, value: station}}
                                                onChange={e => setRefferal(e.value)}
                                            />  
                                        </div> :''
                                    }
                                    <div style={{textAlign: 'left', paddingLeft: '0%', paddingBottom: '5%'}}>
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={previous}>
                                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Previous</span>
                                            <div class="fill-container"></div>
                                        </button>
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={next1}>
                                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Next</span>
                                            <div class="fill-container"></div>
                                        </button>
                                        {warningFirst || warningLast || warningAddress1 || warningAddress2 || warningPostCode || warningState || warningPostCode || warningPhone || warningEmail || warningConfirm || warningNationality || warningDob || warningDlOrigin || warningExperience || warningRefferal  ? 
                                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>missing field/s</p> : ''
                                        }
                                    </div>
                                </div>
                            </div>: steps == 4 ?
                            <div>
                                {props.depot ? <p className='depotApplicationFormTitle'>{props.depot}</p> :''}
                                <div className='loadingBar ' style={{paddingBottom: '3%', borderBottom: '1px solid white'}}>
                                    <BorderLinearProgress variant="determinate" value={80} /><p>80%</p>
                                </div>
                                <div className='extra'>
                                    <h4 className='que'>Calendar <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                    <Calendar minDate={new Date(new Date().getTime() + 86400000)} onChange={e => handleDate(e)}/>
                                    {warningDate ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '0%'}}>{warningDate}</p> : ''
                                    }
                                    <h4 className='que'>Select a time<span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                    <div className="slots" >  
                                        {buttons.length > 0 ? buttons : 'Sorry no slots available on this date!'}
                                    </div>
                                    {warningTime ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '1%'}}>{warningTime}</p> : ''
                                    }
                                    <div style={{textAlign: 'left', paddingLeft: '0%', paddingBottom: '5%', paddingTop: '5%'}}>
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={previous}>
                                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Previous</span>
                                            <div class="fill-container"></div>
                                        </button>
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={next2}>
                                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Next</span>
                                            <div class="fill-container"></div>
                                        </button>
                                        {warningDate || warningTime ? 
                                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>missing field/s</p> : ''
                                        }
                                    </div>
                                </div>
                                
                            </div>: steps == 5 ?
                            <div >
                                {props.depot ? <p className='depotApplicationFormTitle'>{props.depot}</p> :''}
                                <div className='loadingBar ' style={{paddingBottom: '3%', borderBottom: '1px solid white'}}>
                                    <BorderLinearProgress variant="determinate" value={100} /><p>100%</p>
                                </div>
                                <div className='lastPage'>
                                    <h1 style={{textAlign: 'left'}}>Review Answers </h1>
                                    <p style={{textAlign: 'left', fontSize: '20px', marginTop: '3%'}}>Your Application is for our role advertised in Maidstone, Kent, the answers below are what you have provided us today and any information that is incorrect could lead to your application being cancelled. If everything is correct please press submit at the bottom of the page.</p>
                                    <p style={{textAlign: 'left', fontSize: '20px', marginTop: '3%'}}>Your appointment will be at the following address;</p>
                                    {station?.address1 ? <p style={{textAlign: 'left', fontSize: '20px', marginTop: '2%'}}>{station?.address1}</p>: ''}
                                    {station?.address2 ? <p style={{textAlign: 'left', fontSize: '20px', marginTop: '-3%'}}>{station?.address2}</p> : ''}
                                    {station?.town ? <p style={{textAlign: 'left', fontSize: '20px', marginTop: '-3%'}}>{station?.town}</p> : ''}
                                    {station?.county ? <p style={{textAlign: 'left', fontSize: '20px', marginTop: '-3%'}}>{station?.county}</p> : ''}
                                    {station?.postcode ? <p style={{textAlign: 'left', fontSize: '20px', marginTop: '-3%'}}>{station?.postcode}</p> : ''}
                                    <p style={{textAlign: 'left', fontSize: '20px', marginTop: '3%'}}>Information you've provided</p>
                                    <table className='formTable'>
                                        <thead>
                                            <tr className='formTrC1'>
                                                <th className='formTd'>We Asked</th>
                                                <th className='formTdC' >You Said</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Name</td>
                                                <td className='formTdC'>{first + ' ' + last}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Address</td>
                                                <td className='formTdC'>
                                                    {address1}<br></br>
                                                    {address2}<br></br>
                                                    {town}<br></br>
                                                    {state}<br></br>
                                                    {postCode}
                                                </td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Phone Number</td>
                                                <td className='formTdC'>{phone}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Email</td>
                                                <td className='formTdC'>{email}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>You have a basic understanding of the written and spoken English language</td>
                                                <td className='formTdC'>{en}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Your interested in full time work, including weekends</td>
                                                <td className='formTdC'>{fullTime}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>You can drive a manual vehicle, not automatic</td>
                                                <td className='formTdC'>{manual}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>You have a Full UK or EU Licence held for more than 1 year</td>
                                                <td className='formTdC'>{oneYear}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Do you have 6 points or less on your UK driving licence</td>
                                                <td className='formTdC'>{six}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Do you have any CD,DD,DR,IN,MS10,UT50 or TT Endorsements on your driving record</td>
                                                <td className='formTdC'>{endo}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Happy to complete a Drugs & Alcohol screening and a background check</td>
                                                <td className='formTdC'>{dna}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Confident to drive a commercial vehicle</td>
                                                <td className='formTdC'>{driveCon}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Date Of Birth</td>
                                                <td className='formTdC'>{dob?.split('-')[2]+'/'+dob?.split('-')[1]+'/'+dob?.split('-')[0] +' ('+age+' yrs old)'}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Do you own your own commercial vehicle or do you need to rent one?</td>
                                                <td className='formTdC'>{rental == 'Yes' ? 'I Need to Rent a Van' : 'Use my Own Van'}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Do you know your National Insurance No</td>
                                                <td className='formTdC'>{nino ? nino : ''}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Which country was your Licence Issued in</td>
                                                <td className='formTdC'>{dlOrigin}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Licence No</td>
                                                <td className='formTdC'>{dlNo}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Expiry Date</td>
                                                <td className='formTdC'>{dlExp}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Points on Licence</td>
                                                <td className='formTdC'>{points}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Licence Share Code</td>
                                                <td className='formTdC'>{shareCode}</td>
                                            </tr>
                                            <tr className='formTrC2'>
                                                <td className='formTd'>Do you have any experience with Multi Drop Delivery work?</td>
                                                <td className='formTdC'>{car}</td>
                                            </tr>
                                            <tr className='formTrC1'>
                                                <td className='formTd'>Any of these Carriers</td>
                                                <td className='formTdC'>{experience}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div style={{textAlign:'left', marginTop: '3%'}}>
                                        <h4 className='que'>Opt-In<span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="Yes"
                                                name="radio-buttons-group"
                                                onChange={e => setOptIn(e.target.value)}
                                            >
                                                <div className='flex'>
                                                    <FormControlLabel value="No" control={<Radio sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="No" />
                                                    <FormControlLabel value="Yes" control={<Radio  sx={{'&, &.Mui-checked': {color: '#41a3a3',},}}/>} label="Yes" />
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                        <p>Do you want to receive important information regarding your application via WhatsApp?</p>
                                    </div>
                                    <div style={{textAlign:'left', marginTop: '3%', marginBottomL: '10px'}}>
                                            <h4 className='que'>Where did you hear about us? <span style={{color: '#b30000', fontSize: '16px'}}><i>(Required)</i></span></h4>
                                            <Select 
                                                options={lastOption}
                                                styles={colourStyles}
                                                //value={{label: station, value: station}}
                                                onChange={e => handleLead(e.value)}
                                            />  
                                    </div>
                                    {warningLead ? 
                                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'right', marginTop: '1%'}}>{warningLead}</p> : ''
                                    }
                                    <div style={{textAlign: 'left', paddingLeft: '0%', paddingBottom: '15%', paddingTop: '5%'}}>
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={previous}>
                                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Previous</span>
                                            <div class="fill-container"></div>
                                        </button>
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#41a3a3'}} onClick={handleSubmit}>
                                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Submit</span>
                                            <div class="fill-container"></div>
                                        </button>
                                    </div>
                                </div>
                            </div> :
                            <div style={{textAlign: 'left', padding: '5%', fontSize: '20px'}}>
                                <p >Thanks for your application, please check your emails for more information on what to bring with you on the day alternatively you can come back here and message us.</p>
                                <p className='que'>Date/Time: {date?.split('-')[2]+'/'+date?.split('-')[1]+'/'+date?.split('-')[0] +' '+time}</p>
                                <p className='que'>Who with: {station.contact}</p>
                                <p>Contact Details: {station.phone+' / '+station.email}</p>
                                <p className='que'>Address:</p>
                                <p className='que'>{station.address1}</p>
                                <p className='que'>{station.address2}</p>
                                <p className='que'>{station.town}</p>
                                <p className='que'>{station.county}</p>
                                <p className='que'>{station.postcode}</p>
                            </div>
                            }
                            
                        </Paper>    
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                            m: 1,
                            width: 400,
                            height: 1028,
                            },
                        }}
                    >
                        <Paper elevation={3}>
                            <div className='paperTop'>
                                <h4><b>Important Information</b></h4>
                            </div>
                            <div className='paperBottom'>
                                <p className='paperP'>
                                    <b>Please Note:</b> We are looking for candidates to fulfil our Multi Drop Delivery Driver Vacancy in lot’s of locations.
                                </p>
                                <p className='paperP'>
                                    Drivers use their own phone with a delivery app which will provide full navigation during your route and parcels will be organised and ready for collection when you arrive to start loading. Each route is pre-planned to approximately 9hours in a localised delivery area
                                </p>
                                <p className='paperP'>
                                    The depots operate 7 days a week and you have the option to work 4-6 days per week including at least 1 day on the weekends.
                                </p>
                                <p className='paperP'>
                                    Candidates are required to complete a DBS check and attend a drugs and alcohol test to start and this is a self employed position.
                                </p>
                                <p className='paperP'>
                                    A van is required for this position, if you don’t own a van, that’s not a problem we work with a vehicle rental company for great options to get you started. Owner drivers are welcome of course but vehicle size and insurance requirements apply.
                                </p>
                                <p className='paperP'>
                                    No experience is necessary as full training is provided and paid for, as long as you have are a motivated person this could be a great opportunity for you.
                                </p>
                            </div>
                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    )
}
export default AdForm;