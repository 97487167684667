const columnsOrh = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150, hide: true },
    {
        field: 'associate_name',
        headerName: 'Associate Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'schedule_date',
        headerName: 'Schedule Date',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'description',
        headerName: 'Description',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'duration',
        headerName: 'Planed Duration',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'route',
        headerName: 'Route No',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'week_no',
        headerName: 'Week No',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'station',
        headerName: 'Station',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_wave_time',
        headerName: 'Wave Time',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_dispatch_time',
        headerName: 'Dispatch Time',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_end_time',
        headerName: 'End Time',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_actual_duration',
        headerName: 'Actual Duration',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_late_wave',
        headerName: 'Late Wave',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'orh_notes',
        headerName: 'Orh Notes',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    }
]
export default columnsOrh;