import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import authService from "../../services/auth.service";
import columns from "./sumColumns";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha, styled } from '@mui/material/styles';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BlurOnOutlinedIcon from '@mui/icons-material/BlurOnOutlined';
import BlurCircularOutlinedIcon from '@mui/icons-material/BlurCircularOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
}));
export function CustomFooterStatusComponent(props) {
    return (
      <>
      </>
    );
  }

Date.prototype.getWeek = function (dowOffset) {
    dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; 
    var newYear = new Date(this.getFullYear(),0,1);
    var day = newYear.getDay() - dowOffset; 
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((this.getTime() - newYear.getTime() - 
    (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
    var weeknum;
    if(day < 4) {
        weeknum = Math.floor((daynum+day-1)/7) + 1;
        if(weeknum > 52) {
            let nYear = new Date(this.getFullYear() + 1,0,1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            weeknum = nday < 4 ? 1 : 53;
        }
    }
    else {
        weeknum = Math.floor((daynum+day-1)/7);
    }
    return weeknum;
};

const PaymentSummary = () =>{
    const name = authService.getCurrentUser()
    const [ dataRes, setDataRes ] = useState([])
    const [ content, setContent ] = useState([])
    const [ currentWeek, setCurrentWeek ] = useState()
    

    useEffect(()=>{
        UserService.getSinglePaymentData({name: name.username}).then(
            response =>{
                setDataRes(response.data)
            }
        ).catch(err => console.log(err.message))
        setCurrentWeek(new Date().getWeek())
    },[])

    useEffect(()=>{
        let yearWeek = []
        let years = []
        let yearsComp = []
        dataRes?.map(row =>{
            yearWeek.push(row.week_no)
            years.push(row.week_no.split('-')[0])
        })
       
        years = years.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
        })
        years.sort()
        years.reverse()
        yearWeek.sort()
        yearWeek.reverse()
        console.log(yearWeek)
        dataRes?.sort((a,b) => a.week_no.localeCompare(b.week_no))
        dataRes.reverse()
        yearWeek = yearWeek.filter((item, index) => {
            return yearWeek.indexOf(item) == index;
        })
        console.log(yearWeek)
        years?.forEach(item =>{
            let yearWeekComp = []
            yearWeek.forEach(weey =>{
                let accPlc = [] 
                let total = 0
                    dataRes?.map((row, index) =>{
                        if(row.week_no == weey && row.week_no.split('-')[0] == item){
                            accPlc.push({id: index, date: row.schedule_date, description: row.description, amount: Number(row.sub_total) < 0 ? '-£'+(row.sub_total*-1).toFixed(4) : '£'+Number(row.sub_total).toFixed(4)})
                            total += Number(row.sub_total)
                        }  
                    })  
                    accPlc.push({id: 1000, date: '', description: 'Total', amount: total < 0 ? '-£'+total.toFixed(4)*-1 : '£'+total.toFixed(4)})   
                    if(weey.split('-')[0] == item){
                        yearWeekComp.push(  <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Week {weey.split('-')[1]}{(currentWeek - Number(weey.split('-')[1])) < 3  && Number(weey.split('-')[0]) == new Date().getFullYear() ? <><AdjustOutlinedIcon style={{color: '#eb7a3e', marginLeft: '10%'}}/>  Outstanding</> : Number(weey.split('-')[0]) < new Date().getFullYear() && Number(weey.split('-')[1])-currentWeek > 49 ? <><AdjustOutlinedIcon style={{color: '#eb7a3e', marginLeft: '10%'}}/>  Outstanding</> : <><BlurCircularOutlinedIcon style={{color: '#33cc33', marginLeft: '10%'}}/>  Paid</>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '-10%', marginTop:2 }}>
                                    <StripedDataGridPro
                                    rows={accPlc}
                                    
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 100,
                                            },
                                        },
                                        columns: {
                                            columnVisibilityModel: {
                                                id: false,
                                            },
                                        },
                                    }}
                                    sx={{fontSize: '9px', fontFamily: ''}}
                                    pageSizeOptions={[100]}
                                    slots={{
                                        loadingOverlay: LinearProgress,
                                        footer: CustomFooterStatusComponent
                                    }}
                                    
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                    }
                                    disableColumnResize={true}
                
                                    />
                                </Box>
                               
                            </AccordionDetails>
                        </Accordion>)
                    }
            })
            
            
            yearsComp.push( <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                //aria-controls="panel2-content"
                //id="panel2-header"
                >
                    {item}
                </AccordionSummary>
                <AccordionDetails>
                    {yearWeekComp}
                </AccordionDetails>
            </Accordion>)
        })
        setContent(yearsComp)
        
        },[dataRes])

    return(
        <>
           {content}
        </>
    )
}
export default PaymentSummary;