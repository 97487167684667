import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import Reset from "./components/reset.component";
import { CCollapse, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider } from '@coreui/react'
import UsersAccess from "./components/admin/uAndAccess.component";
import UserDetails from "./components/admin/userDetails.component";
import PlaceTicket from "./components/ticket/placeATicket.component";
import DepartTickets from "./components/ticket/departmetsTickets.component";
import AssociatesInfo from "./components/associates/associatesInfo.component";
import Profiles from "./components/associates/profiles.component";
import EditProfile from "./components/associates/editForm.component";
import AuditData from "./components/compliance/auditTable.component";
import AuthVerify from "./common/auth-verify";
import IncidentForm from "./components/compliance/incidentForm.component";
import EventBus from "./common/EventBus";
import Dash from "./components/dashboard/dash.component";
import DspScorecard from "./components/kpi/dspScorecards.component";
import PmUpdate from "./components/osmTools/pmUpdate.component";
import Statistics from "./components/osmTools/statistics.component";
import Telegram from "./components/osmTools/telegram.component";
import IncidentReports from "./components/compliance/incidentReports.component";
import Archive from "./components/associates/archive.component";
import ApplicationView from "./components/recruitment/application.component";
import OnboardingView from "./components/recruitment/onboarding.component";
import DataCapture from "./components/recruitment/dataCapture.component";
import Policies from "./components/compliance/policies.component";
import AdForm from "./components/ad/ad.component";
import SlotsForm from "./components/recruitment/timeSlots.component";
import skLogo from "./sksoLogo.png";
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import TAndC from "./components/terms/tAndC.component";
import Debtors from "./components/finance/debtors.component";
import ArchiveData from "./components/associates/archiveData.component";
import { LicenseInfo } from '@mui/x-license';
import AmzlMileage from "./components/finance/amzlMileage.component";
import RateCard from "./components/finance/rateCard.component";
import PaymentDatabase from "./components/finance/paymentDatabase.component";
import OsmPaymentDatabase from "./components/finance/osmPaymentDatabase.component";
import ApplicationForms from "./components/ad/applicationForms.component";
import OnboardingViewOsm from "./components/recruitment/onboardingOSM.component";
import OrhTable from "./components/compliance/orhTable.component";
import ScorecardData from "./components/kpi/scoreCardData.component";
import ScorecardDataOsm from "./components/kpi/scoreCardDataOsm.component";


LicenseInfo.setLicenseKey('34752b38e4628ad3ffd02103a41623dcTz0xMDA1OTUsRT0xNzYxMzc5NDcwMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');



class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });

  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
   
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
    
    return (
      <div style={{width: '100%'}}>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
        
          <Link to={"/"} className="navbar-brand">
            <img src={skLogo} style={{width: '100px'}}/>
          </Link>
          <div className="navbar-nav mr-auto">
            {currentUser && (<li className="nav-item hide">
              <Link to={"/dash"} className="nav-link">
                Dashboard
              </Link>
            </li>)}
            {currentUser && (currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast')) && (
              <CDropdown dark component="li" variant="nav-item" className="hide">
                <CDropdownToggle>Associates</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/associatesInfo">
                      Associates Info
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/profiles">
                      Associates Profiles
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/archiveData">
                      Archive
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/archiveEdit">
                      Archive Edit
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/editProfile">
                      Edit Associates
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            )}

            {currentUser && (
              <CDropdown dark component="li" variant="nav-item" className="hide">
                <CDropdownToggle>Compliance</CDropdownToggle>
                <CDropdownMenu>
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') ?
                    <>
                      <CDropdownItem href="/audit">
                          Audit
                      </CDropdownItem>
                        <CDropdownDivider /> 
                    </>
                    : ''}
                    {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') ?
                    <>
                      <CDropdownItem href="/orhData">
                          On Road Hours
                      </CDropdownItem>
                        <CDropdownDivider /> 
                    </>
                    : ''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO') || currentUser.department?.match('Driver') ?
                    <>
                      <CDropdownItem href="/incident">
                        Report Incident
                      </CDropdownItem>
                    {JSON.parse(currentUser.department)[0] !== 'Driver' ? <CDropdownDivider /> : ''}
                  </>
                  : ''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') ?
                    <>
                      <CDropdownItem href="/incidentReports">
                        Incidents
                      </CDropdownItem>
                      <CDropdownDivider />
                    </>
                  : ''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO') ?
                    <>
                      <CDropdownItem href="/policies">
                          Policies 
                      </CDropdownItem>
                    </>
                  : ''}
                </CDropdownMenu>
              </CDropdown>
            )}

            {currentUser && (currentUser.department?.match('Senior Management') /*|| currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast')*/ || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO')) && (
              <CDropdown dark component="li" variant="nav-item" className="hide">
                <CDropdownToggle>Finances</CDropdownToggle>
                <CDropdownMenu>
                  {/*currentUser.department?.match('Senior Management') ?
                  <>
                    <CDropdownItem href="/">
                        Payment Entry
                    </CDropdownItem>
                    <CDropdownDivider />
                  </>
                   : ''*/}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO') ? 
                  <>
                    <CDropdownItem href="/paymentOsmDatabase">
                        Osm Payment Database
                    </CDropdownItem>
                    <CDropdownDivider />
                  </>:''
                  }
                  {currentUser.department?.match('Senior Management') ?
                  <>
                    <CDropdownItem href="/paymentDatabase">
                        Payment Database
                    </CDropdownItem>
                    <CDropdownDivider />
                  </>
                   : ''}
                  {currentUser.department?.match('Senior Management') ?
                  <>
                    <CDropdownItem href="/rateCard">
                        Rate Card
                    </CDropdownItem>
                    <CDropdownDivider />
                  </>
                  : ''}
                  {currentUser.department?.match('Senior Management') ?
                  <>
                    <CDropdownItem href="/amzlMileage">
                        Amzl Mileage
                    </CDropdownItem>
                    <CDropdownDivider />
                  </>
                  : ''}
                  {currentUser.department?.match('Senior Management') ?
                  <>
                    <CDropdownItem href="/debtors">
                        Debtors
                    </CDropdownItem>
                  </>
                  : ''}
                </CDropdownMenu>
              </CDropdown>
            )}

            {currentUser && (currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') /*|| currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO')*/) && (
              <CDropdown dark component="li" variant="nav-item" className="hide">
                <CDropdownToggle>KPI's</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/scorecardData">
                      Score Card Data
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/scorecardDataOsm">
                      Score Card Data(OSM)
                  </CDropdownItem>
                  {/*<CDropdownItem href="/dspScorecard">
                      Station Score Cards
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/">
                      Add Concession Data
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/">
                      Add Score Card Data
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/">
                      Concessions 
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/">
                      DA Score Cards
                  </CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/">
                      Score Cards
                  </CDropdownItem>*/}
                </CDropdownMenu>
              </CDropdown>
            )}

          {currentUser && JSON.parse(currentUser.department)[0] !== 'Driver' && (
              <CDropdown dark component="li" variant="nav-item" className="hide">
                <CDropdownToggle>Tools</CDropdownToggle>
                <CDropdownMenu>
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') ?
                    <>
                      <CDropdownItem href="/telegram">
                          Telegram Messages
                      </CDropdownItem>
                      <CDropdownDivider />
                    </>
                  : ''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO') ?
                    <> 
                      <CDropdownItem href="/pmUpdate">
                          Daily Update
                      </CDropdownItem>
                      {JSON.parse(currentUser.department)[0] !== 'DME4-SKSO' && JSON.parse(currentUser.department)[0] !== 'DSS2-SKSO' && JSON.parse(currentUser.department)[0] !== 'DRM4-SKSO' && JSON.parse(currentUser.department)[0] !== 'DXE1-SKSO' ? <CDropdownDivider /> : ''}
                    </>
                  : ''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') ?
                    <CDropdownItem href="/statistics">
                        Statistics
                    </CDropdownItem> : ''
                  }
                </CDropdownMenu>
              </CDropdown>
            )}

            {currentUser && JSON.parse(currentUser.department)[0] !== 'Driver' &&  (
              <CDropdown dark component="li" variant="nav-item" className="hide">
                <CDropdownToggle>Recruitment</CDropdownToggle>
                <CDropdownMenu>
                {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast')  ?
                    <>
                      <CDropdownItem href="/applicationForms">
                          Application Forms
                      </CDropdownItem>
                      <CDropdownDivider />
                    </> 
                  :''}
                 {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast')  ?
                    <>
                      <CDropdownItem href="/applicationView">
                          Application View
                      </CDropdownItem>
                      <CDropdownDivider />
                    </> 
                  :''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast')  ?
                    <>
                      <CDropdownItem href="/onboardingView">
                          Onboarding View
                      </CDropdownItem>
                      <CDropdownDivider /> 
                    </>
                  : ''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO')  ?
                    <>
                      <CDropdownItem href="/onboardingViewOsm">
                          Onboarding View (OSM)
                      </CDropdownItem>
                      <CDropdownDivider /> 
                    </>
                  : ''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO') ?
                    <>
                      <CDropdownItem href="/dataCapture">
                          Onboarding - Data Capture 
                      </CDropdownItem>
                      {JSON.parse(currentUser.department)[0] !== 'DME4-SKSO' && JSON.parse(currentUser.department)[0] !== 'DSS2-SKSO' && JSON.parse(currentUser.department)[0] !== 'DRM4-SKSO' && JSON.parse(currentUser.department)[0] !== 'DXE1-SKSO' ? <CDropdownDivider /> : ''}
                    </>  
                  : ''}
                  {currentUser.department?.match('Senior Management') || currentUser.department?.match('Regional Manager')  ?
                    <CDropdownItem href="/interviewSlots">
                      Manage slots 
                    </CDropdownItem>
                  :''}
                </CDropdownMenu>
              </CDropdown>
            )}

            {currentUser ?
              <CDropdown dark component="li" variant="nav-item" className="hide">
                <CDropdownToggle>Tickets</CDropdownToggle>
                <CDropdownMenu>
                  {currentUser?.department && currentUser.department?.match('Senior Management') ? <><CDropdownItem href="/seniorRoute">
                      Senior Management 
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('IT Support') ? <><CDropdownItem href="/itRoute">
                      IT Support
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('Recruitment') ? <><CDropdownItem href="/recRoute">
                      Recruitment 
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('Tambor Fleet') ? <><CDropdownItem href="/fleetRoute">
                      Fleet 
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('Tracfast') ? <><CDropdownItem href="/tracRoute">
                      Tracfast
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('Regional Manager') ? <><CDropdownItem href="/rmRoute">
                      Regional Management
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('DME4') ? <><CDropdownItem href="/dmeRoute">
                      DME4-SKSO
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('DRH1') ? <><CDropdownItem href="/drhRoute">
                      DRH1-SKSO
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('DXE1') ? <><CDropdownItem href="/dxeRoute">
                      DXE1-SKSO
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('DRM4') ? <><CDropdownItem href="/drmRoute">
                      DRM4-SKSO
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  {currentUser?.department && currentUser.department?.match('DSS2') ? <><CDropdownItem href="/dssRoute">
                      DSS2-SKSO
                  </CDropdownItem>
                  <CDropdownDivider /></>: ''}
                  <CDropdownItem href="/placeTicket">
                      Place a Ticket
                      </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>: ''
            }

            

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {/*
              currentUser?.department && currentUser?.department.match('IT Support') ? 
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Test Board
                </Link>
              </li>:''
          */}

            {showAdminBoard && (
              <CDropdown dark component="li" variant="nav-item"  className="hide">
                <CDropdownToggle>Admin</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/uAccess">
                      Users and access
                  </CDropdownItem>
                 {/* <CDropdownDivider />
                  <CDropdownItem href="/">
                      Departments
                  </CDropdownItem>
                    <CDropdownDivider />
                  <CDropdownItem href="/userDetails">
                      User details
                   </CDropdownItem>*/}
                </CDropdownMenu>
              </CDropdown>
            )}

            {/*currentUser && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  User
                </Link>
              </li>
            )*/}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto hide">
              <li className="nav-item" >
                <Link to={"/profile"} className="nav-link" style={{color: 'black'}}>
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item hide">
                <a href="/login" className="nav-link" onClick={this.logOut} style={{color: 'black'}}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link" style={{color: 'black'}}>
                  Login
                </Link>
              </li>

              {/*<li className="nav-item">
                <Link to={"/register"} className="nav-link" style={{color: 'black'}}>
                  Sign Up
                </Link>
              </li>*/}
            </div>
          )}
        


        {/*implementing mobile menu-----------------------------------------------------*/}
          {currentUser  ?
              <CDropdown dark component="li" variant="nav-item" className="unhide">
                  <CDropdownToggle ><MenuOpenRoundedIcon sx={{fontSize: 'xx-large !important'}}/></CDropdownToggle>
                  <CDropdownMenu>
                    {currentUser?.department && currentUser.department?.match('Driver') ? 
                      <>
                        <CDropdownItem href="/dash">
                            Dashboard
                        </CDropdownItem>
                        <CDropdownDivider /> 
                      </>
                    :''}
                    {currentUser?.department && (currentUser.department?.match('Driver') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DSS2-SKSO')) ?
                      <>
                        <CDropdownItem href="/incident">
                          Report Incident
                        </CDropdownItem>
                        <CDropdownDivider /> 
                      </>
                    :''}
                     {/*currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO') ?
                      <>
                        <CDropdownItem href="/telegram">
                            Telegram Messages
                        </CDropdownItem>
                        <CDropdownDivider />
                      </>
                    : ''*/}
                     {/*currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO') ?
                      <> 
                        <CDropdownItem href="/pmUpdate">
                            Daily Update
                        </CDropdownItem>
                        <CDropdownDivider /> 
                      </>
                    : ''*/}
                    {currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO') ?
                      <>
                        <CDropdownItem href="/dataCapture">
                            Onboarding - Data Capture 
                        </CDropdownItem>
                        <CDropdownDivider /> 
                      </>  
                    : ''}
                    {currentUser?.department && (currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DSS2-SKSO')) ?
                      <>
                        <CDropdownItem href="/policies">
                            Policies 
                        </CDropdownItem>
                        <CDropdownDivider /> 
                      </>
                    :''}
                    {currentUser?.department && currentUser.department?.match('Senior Management') ? <><CDropdownItem href="/seniorRoute">
                      Tickets - Senior Management 
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('IT Support') ? <><CDropdownItem href="/itRoute">
                      Tickets - IT Support
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('Recruitment') ? <><CDropdownItem href="/recRoute">
                      Tickets - Recruitment 
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('Tambor Fleet') ? <><CDropdownItem href="/fleetRoute">
                      Tickets - Fleet 
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('Tracfast') ? <><CDropdownItem href="/tracRoute">
                      Tickets - Tracfast
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('Regional Manager') ? <><CDropdownItem href="/rmRoute">
                      Tickets - Regional Management
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('DME4') ? <><CDropdownItem href="/dmeRoute">
                      Tickets - DME4-SKSO
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('DRH1') ? <><CDropdownItem href="/drhRoute">
                      Tickets - DRH1-SKSO
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('DXE1') ? <><CDropdownItem href="/dxeRoute">
                      Tickets - DXE1-SKSO
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('DRM4') ? <><CDropdownItem href="/drmRoute">
                      Tickets - DRM4-SKSO
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && currentUser.department?.match('DSS2') ? <><CDropdownItem href="/dssRoute">
                      Tickets - DSS2-SKSO
                    </CDropdownItem>
                    <CDropdownDivider /></>: ''}
                    {currentUser?.department && (currentUser.department?.match('Driver') || currentUser.department?.match('Senior Management') || currentUser.department?.match('IT Support') || currentUser.department?.match('Recruitment') || currentUser.department?.match('Tambor Fleet') || currentUser.department?.match('Regional Manager') || currentUser.department?.match('Tracfast') || currentUser.department?.match('DME4-SKSO') || currentUser.department?.match('DXE1-SKSO') || currentUser.department?.match('DRM4-SKSO') || currentUser.department?.match('DSS2-SKSO')) ?
                      <>
                        <CDropdownItem href="/placeTicket">
                          Place a Ticket
                          </CDropdownItem>
                        <CDropdownDivider /> 
                      </>
                    :''}
                    <CDropdownItem href="/profile">
                      {currentUser.username}
                    </CDropdownItem>
                    <CDropdownDivider />
                    <CDropdownItem href="/login" onClick={this.logOut}>
                        LogOut
                    </CDropdownItem>
                  </CDropdownMenu>  
              </CDropdown>  :''
          }
        {/*implementing mobile menu-----------------------------------------------------*/}

        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={/*<Profile />*/ <Dash />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/uAccess" element={<UsersAccess />} />
            <Route path="/userDetails" element={<UserDetails />} />
            <Route path="/placeTicket" element={<PlaceTicket />} />
            <Route path="/seniorRoute" element={<DepartTickets department={'Senior Management'} />} />
            <Route path="/itRoute" element={<DepartTickets department={'IT Support'} />} />
            <Route path="/recRoute" element={<DepartTickets department={'Recruitment'} />} />
            <Route path="/fleetRoute" element={<DepartTickets department={'Tambor Fleet'} />} />
            <Route path="/tracRoute" element={<DepartTickets department={'Tracfast'} />} />
            <Route path="/rmRoute" element={<DepartTickets department={'Regional Management'} />} />
            <Route path="/dmeRoute" element={<DepartTickets department={'DME4-SKSO'} />} />
            <Route path="/drhRoute" element={<DepartTickets department={'DRH1-SKSO'} />} />
            <Route path="/dxeRoute" element={<DepartTickets department={'DXE1-SKSO'} />} />
            <Route path="/drmRoute" element={<DepartTickets department={'DRM4-SKSO'} />} />
            <Route path="/dssRoute" element={<DepartTickets department={'DSS2-SKSO'} />} />
            <Route path="/associatesInfo" element={<AssociatesInfo />} />
            <Route path="/profiles" element={<Profiles />} />
            <Route path="/editProfile" element={<EditProfile />} />
            <Route path="/audit" element={<AuditData />} />
            <Route path="/incident" element={<IncidentForm />} />
            <Route path="/dash" element={<Dash />} />
            <Route path="/dspScorecard" element={<DspScorecard />} />
            <Route path="/pmUpdate" element={<PmUpdate />} />
            <Route path="/statistics" element={<Statistics />} />
            <Route path="/telegram" element={<Telegram />} />
            <Route path="/incidentReports" element={<IncidentReports />} />
            <Route path="/archiveEdit" element={<Archive />} />
            <Route path="/applicationView" element={<ApplicationView />} />
            <Route path="/onboardingView" element={<OnboardingView />} />
            <Route path="/onboardingViewOsm" element={<OnboardingViewOsm />} />
            <Route path="/dataCapture" element={<DataCapture />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/adForm"element={<AdForm />} />
            <Route path="/interviewSlots" element={<SlotsForm />} />
            <Route path="/tAndC" element={<TAndC /> } />
            <Route path="/debtors" element={<Debtors />} />
            <Route path="/archiveData" element={<ArchiveData />} />
            <Route path="/amzlMileage" element={<AmzlMileage />} />
            <Route path="/rateCard" element={<RateCard />} />
            <Route path="/paymentDatabase" element={<PaymentDatabase />} />
            <Route path="/paymentOsmDatabase" element={<OsmPaymentDatabase />} />
            <Route path="/applicationForms" element={<ApplicationForms />} />
            <Route path="/orhData" element={<OrhTable />} />
            <Route path="/scorecardData" element={<ScorecardData />} />
            <Route path="/scorecardDataOsm" element={<ScorecardDataOsm />} />
          </Routes>
        </div>

        <AuthVerify logOut={this.logOut}/>
        
      </div>
      
    );
  }
}

export default App;
