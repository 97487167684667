const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150, hide: true },
     {
        field: 'week',
        headerName: 'Week No',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'station',
        headerName: 'Station',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'associate',
        headerName: 'Associate',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'amzl_id',
        headerName: 'Transporter Id',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'delivered',
        headerName: 'Packages Delivered',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'dcr',
        headerName: 'DCR',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'dnr_dpmo',
        headerName: 'DNR DPMO',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'pod',
        headerName: 'POD',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'cc',
        headerName: 'CC',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'ce',
        headerName: 'CE',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'dex',
        headerName: 'DEX',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    },
    {
        field: 'concessions',
        headerName: 'Concessions',
        headerClassName: 'table-header',
        width: 160,
        editable: false,
    }
]
export default columns;