import React from "react";
import { useState, useEffect, useRef } from "react";
import CardMedia from '@mui/material/CardMedia';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import axios from "axios";
import useVisible from "./useFirst";
import { useInView } from 'react-intersection-observer';
import { Suspense } from "react";
import RenderOnViewportEntry from "./renderView";
import Avatar from '@mui/material/Avatar';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Avatart = (props) =>{
    const [ loaded, setLoaded ] = useState(true)
    const [ link, setLink ] = useState()
  

    useEffect(() =>{
        setLink(props.link)
        axios.get(props.link).then(() => setLoaded(true)).catch(()=> setLoaded(false))
    },[props.link])

    return(
        <>
        
        {
        loaded ? 
        <Avatar alt="Remy Sharp" sx={{ width: 76, height: 76 , boxShadow: "0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 10px 26px 0 rgba(0, 0, 0, 0.19) inset", border: '2px solid rgb(45, 117, 131)'}}>
            <LazyLoadImage
      
            height="80"
            style={{ width: "80px"}}
            src={props.link} // use normal <img> attributes as props
            />
        </Avatar >
                    : <Avatar alt="Remy Sharp" sx={{ width: 76, height: 76,  boxShadow: "0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 10px 26px 0 rgba(0, 0, 0, 0.19) inset", border: '2px solid rgb(45, 117, 131)' }}><AccountCircleOutlinedIcon style={{fontSize: '200px', color: '#e2e2e2',  width: "76px", height: "76px" }} /></Avatar >
        }
        
        </>
    )
}
export default Avatart;
/*<CardMedia
    <Avatar alt="Remy Sharp" src={props.link} /> 
                    component="img"
                    height="50"
                    style={{ width: "85px"}}
                    src={encodeURI(link)} 
                    onError={(err)=>{ setLoaded(false)}}                   
                    alt='green iguana'
                /> */