import React, { Component } from "react";
import clip from './img/backTry.mp4';
import UserService from "../services/user.service";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
      <div style={{height: '1200px'}}>
        
      <video id="background-video" loop muted autoPlay>
            <source src={clip} type="video/mp4" />
            <source src={clip} type="video/ogg" />
            Your browser does not support the video tag.
        </video>
     {/* <div className="container">
        
        <header className="jumbotron">
        
          <h3>{this.state.content}</h3>
        </header>
      </div>*/}
      </div>
    );
  }
}
