import React from "react";
import { useState, useEffect, useRef } from "react";
import CardMedia from '@mui/material/CardMedia';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import axios from "axios";
import useVisible from "./useFirst";
import { useInView } from 'react-intersection-observer';
import { Suspense } from "react";
import RenderOnViewportEntry from "./renderView";
import Avatar from '@mui/material/Avatar';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Picture = (props) =>{
    const [ loaded, setLoaded ] = useState(true)
    const [ link, setLink ] = useState()
  

    useEffect(() =>{
        setLink(props.link)
        axios.get(props.link).then(() => setLoaded(true)).catch(()=> setLoaded(false))
    },[props.link])

    return(
        <>
        
        {
        loaded ? 
                    <CardMedia
       
                        component="img"
                        height="250"
                        style={{ width: "285px",  border: '2px solid rgb(45, 117, 131)'}}
                        src={encodeURI(link)} 
                        onError={(err)=>{ setLoaded(false)}}                   
                        alt='green iguana'
                    /> 
                    : <AccountCircleOutlinedIcon style={{fontSize: '200px', color: '#e2e2e2',  width: "76px", height: "76px", border: '2px solid rgb(45, 117, 131)' }} />
        }
        
        </>
    )
}
export default Picture;
/*<CardMedia
    <Avatar alt="Remy Sharp" src={props.link} /> 
                    component="img"
                    height="50"
                    style={{ width: "85px"}}
                    src={encodeURI(link)} 
                    onError={(err)=>{ setLoaded(false)}}                   
                    alt='green iguana'
                /> */