const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'date',
        headerName: 'Date',
        headerClassName: 'table-header',
        width: 70,
        editable: false,
      },
    {
        field: 'description',
        headerName: 'Description',
        headerClassName: 'table-header',
        width: 110,
        editable: false,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        headerClassName: 'table-header',
        width: 90,
        editable: false,
      }
]
export default columns;