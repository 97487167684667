import React from "react";
import { useState, useEffect } from "react";
import userService from "../../services/user.service";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import AdForm from "./ad.component";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


const ApplicationForms = () =>{
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  

    return(
        <div className='dashArch'>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'fit-content' }}
            >
                <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="DSS2" {...a11yProps(0)} />
                    <Tab label="DME4" {...a11yProps(1)} />
                    <Tab label="DXE1" {...a11yProps(2)} />
                    <Tab label="DRM4" {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <AdForm depot={'DSS2-SKSO'} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AdForm depot={'DME4-SKSO'} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <AdForm depot={'DXE1-SKSO'} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <AdForm depot={'DRM4-SKSO'} />
                </TabPanel>
            </Box>
        </div>
    )
}
export default ApplicationForms;