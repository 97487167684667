import React from "react";
import { useState, useEffect } from "react";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import UserService from "../../services/user.service";
import columns from "./associatesI";
import BlurOnOutlinedIcon from '@mui/icons-material/BlurOnOutlined';
import BlurCircularOutlinedIcon from '@mui/icons-material/BlurCircularOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import TollIcon from '@mui/icons-material/Toll';
import { alpha, styled } from '@mui/material/styles';


const ODD_OPACITY = 0.2;

const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {/*<GridToolbarExport />*/}
        <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}


const  ArchiveData = () =>{
    const [ userDetails, setUserDetails ] = useState()
    const [ loading, setLoading ] = useState(false)
    const [ search, setSearch ] = useState([])
    const [ filter, setFilter ] = useState([false, false, false, false, false, false])
    const [ pending, setPending ] = useState(false)
    const [ onboarding, setOnboarding ] = useState(false)
    const [ onboarded, setOnboarded ] = useState(false)
    const [ inactive, setInactive ] = useState(false)
    const [ offBoarded, setOffBoarded] = useState(false)
    const [ filtered, setFiltered ] = useState([])
    const [ logCheck, setLogCheck ] = useState(true)

    useEffect(()=>{
        setLoading(true)
        UserService.getArchiveX().then(
            response => {
                console.log(response.data)
                setUserDetails(response.data)
                setLoading(false)
            }
        ).catch(err =>{
            console.log(err)
            if(err.message == "Request failed with status code 403"){
                setLogCheck(false)
            }
        })
    },[])

    
    let display = []
    userDetails?.map(detail =>{
        display.push({
            id: detail.entry, 
            status: detail.status,
            account_no: detail.account_no, 
            station: detail.station,
            skso_role: detail.skso_role,
            name: detail.name,
            email: detail.email,
            phone: detail.phone,
            address1: detail.address1, 
            address2: detail.address2,
            town: detail.town,
            county: detail.county,
            post_code: detail.post_code,
            nationality: detail.nationality,
            dob: detail.dob ? detail.dob : '',            
            age: detail.age,
            nino: detail.nino,
            vehicle_source: detail.vehicle_source, 
            dl_origin: detail.dl_origin,
            dl_no: detail.dl_no,
            dl_expiry: detail.dl_expiry ? detail.dl_expiry?.split('T').length > 1 ? detail.dl_expiry?.split('T')[0].split('-')[2]+'/'+detail.dl_expiry?.split('T')[0].split('-')[1]+'/'+detail.dl_expiry?.split('T')[0].split('-')[0] : detail.dl_expiry?.split('-')[2]+'/'+detail.dl_expiry?.split('-')[1]+'/'+detail.dl_expiry?.split('-')[0] : '',
            dl_points: detail.dl_points,
            apply_interview_date: detail.apply_interview_date ? detail.apply_interview_date?.split('T').length > 1 ?  detail.apply_interview_date?.split('T')[0].split('-')[2]+'/'+detail.apply_interview_date?.split('T')[0].split('-')[1]+'/'+detail.apply_interview_date?.split('T')[0].split('-')[0] : detail.apply_interview_date?.split('-')[2]+'/'+detail.apply_interview_date?.split('-')[1]+'/'+detail.apply_interview_date?.split('-')[0] : '',
            apply_interview_time: detail.apply_interview_time,
            apply_onboarding_skso_acc: detail.apply_onboarding_skso_acc,
            apply_da_date: detail.apply_da_date ? detail.apply_da_date?.split('T').length > 1 ? detail.apply_da_date?.split('T')[0].split('-')[2]+'/'+detail.apply_da_date?.split('T')[0].split('-')[1]+'/'+detail.apply_da_date?.split('T')[0].split('-')[0] : detail.apply_da_date?.split('-')[2]+'/'+detail.apply_da_date?.split('-')[1]+'/'+detail.apply_da_date?.split('-')[0] : '',
            apply_da_time: detail.apply_da_time,
            apply_da_result: detail.apply_da_result	,
            onboarding_rtw_check: detail.onboarding_rtw_check,
            onboarding_bgc_completed: detail.onboarding_bgc_completed ? detail.onboarding_bgc_completed?.split('T').length > 1 ? detail.onboarding_bgc_completed?.split('T')[0].split('-')[2]+'/'+detail.onboarding_bgc_completed?.split('T')[0].split('-')[1]+'/'+detail.onboarding_bgc_completed?.split('T')[0].split('-')[0] : detail.onboarding_bgc_completed?.split('-')[2]+'/'+detail.onboarding_bgc_completed?.split('-')[1]+'/'+detail.onboarding_bgc_completed?.split('-')[0] :'',
            onboarding_date: detail.onboarding_date	? detail.onboarding_date?.split('T').length > 1 ? detail.onboarding_date?.split('T')[0].split('-')[2]+'/'+detail.onboarding_date?.split('T')[0].split('-')[1]+'/'+detail.onboarding_date?.split('T')[0].split('-')[0] : detail.onboarding_date?.split('-')[2]+'/'+detail.onboarding_date?.split('-')[1]+'/'+detail.onboarding_date?.split('-')[0] : '',
            active_utr: detail.active_utr,
            active_amzl_id: detail.amzl_id,
            active_pay_cycle: detail.active_pay_cycle,
            active_co_name: detail.active_co_name,
            active_co_reg: detail.active_co_reg,
            active_co_vat: detail.active_co_vat,
            active_bank_name: detail.active_bank_name,
            active_bank: detail.active_bank,
            active_bank_ref: detail.active_bank_ref,
            active_sort_code: detail.active_bank_sort_code,
            active_account_no: detail.active_bank_account_no,
            audit_dl_no: detail.audit_dl_no,
            audit_dl_expiry: detail.audit_dl_expiry ? detail.audit_dl_expiry?.split('T').length > 1 ? detail.audit_dl_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_dl_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_dl_expiry?.split('T')[0].split('-')[0] : detail.audit_dl_expiry?.split('-')[2]+'/'+detail.audit_dl_expiry?.split('-')[1]+'/'+detail.audit_dl_expiry?.split('-')[0] : '',
            audit_dl_origin: detail.audit_dl_origin,
            audit_dl_points: detail.audit_dl_points,
            audit_dl_endorsements: detail.audit_dl_endorsements,
            audit_dl_check_date: detail.audit_dl_check_date ? detail.audit_dl_check_date?.split('T')[0].length > 0 ? detail.audit_dl_check_date?.split('T')[0].split('-')[2]+'/'+detail.audit_dl_check_date?.split('T')[0].split('-')[1]+'/'+detail.audit_dl_check_date?.split('T')[0].split('-')[0] : detail.audit_dl_check_date?.split('-')[2]+'/'+detail.audit_dl_check_date?.split('-')[1]+'/'+detail.audit_dl_check_date?.split('-')[0] : '',
            audit_rtw1: detail.audit_rtw1,
            audit_rtw1_doc: detail.audit_rtw1_doc,
            audit_rtw1_expiry: detail.audit_rtw1_expiry ? detail.audit_rtw1_expiry?.split('T').length > 1 ? detail.audit_rtw1_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_rtw1_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_rtw1_expiry?.split('T')[0].split('-')[0] : detail.audit_rtw1_expiry?.split('-')[2]+'/'+detail.audit_rtw1_expiry?.split('-')[1]+'/'+detail.audit_rtw1_expiry?.split('-')[0] : '',
            audit_rtw2: detail.audit_rtw2,
            audit_rtw2_doc: detail.audit_rtw2_doc,
            audit_rtw2_expiry: detail.audit_rtw2_expiry ? detail.audit_rtw2_expiry?.split('T').length > 1 ? detail.audit_rtw2_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_rtw2_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_rtw2_expiry?.split('T')[0].split('-')[0] : detail.audit_rtw2_expiry?.split('-')[2]+'/'+detail.audit_rtw2_expiry?.split('-')[1]+'/'+detail.audit_rtw2_expiry?.split('-')[0] : '',
            audit_rtw_check: detail.audit_rtw_check ? detail.audit_rtw_check?.split('T')[0].length > 1 ? detail.audit_rtw_check?.split('T')[0].split('-')[2]+'/'+detail.audit_rtw_check?.split('T')[0].split('-')[1]+'/'+detail.audit_rtw_check?.split('T')[0].split('-')[0] : detail.audit_rtw_check?.split('-')[2]+'/'+detail.audit_rtw_check?.split('-')[1]+'/'+detail.audit_rtw_check?.split('-')[0] : '',
            updatedBy: detail.updatedBy,
            notes: detail.notes,
            offboarding_request_by: detail.offboarding_request_by,
            offboarding_date: detail.offboarding_date ? new Date(detail.offboarding_date).toLocaleDateString('en-GB') : '',
            offboarding_reason: detail.offboarding_reason,
            offboarding_badge_status: detail.offboarding_badge_status,
            offboarding_security_aware: detail.offboarding_security_aware,
            offboarding_action_by: detail.offboarding_action_by
        })
    })

    const handleSearch = (e) =>{
        const searchE = e.target.value
        setSearch([])
            userDetails?.map(detail =>{
                
                if(detail.name?.toLowerCase().match(searchE) || detail.station?.toLowerCase().match(searchE || detail.status?.toLowerCase().match(searchE) || detail.email_w?.toLowerCase().match(searchE))){
                    let data = {
                        id: detail.entry, 
                        status: detail.status,
                        account_no: detail.account_no, 
                        station: detail.station,
                        skso_role: detail.skso_role,
                        name: detail.name,
                        email: detail.email,
                        phone: detail.phone,
                        address1: detail.address1, 
                        address2: detail.address2,
                        town: detail.town,
                        county: detail.county,
                        post_code: detail.post_code,
                        nationality: detail.nationality,
                        dob: detail.dob ? detail.dob : '',                        
                        age: detail.age,
                        nino: detail.nino,
                        vehicle_source: detail.vehicle_source, 
                        dl_origin: detail.dl_origin,
                        dl_no: detail.dl_no,
                        dl_expiry: detail.dl_expiry ? detail.dl_expiry?.split('T').length > 1 ? detail.dl_expiry?.split('T')[0].split('-')[2]+'/'+detail.dl_expiry?.split('T')[0].split('-')[1]+'/'+detail.dl_expiry?.split('T')[0].split('-')[0] : detail.dl_expiry?.split('-')[2]+'/'+detail.dl_expiry?.split('-')[1]+'/'+detail.dl_expiry?.split('-')[0] : '',
                        dl_points: detail.dl_points,
                        apply_interview_date: detail.apply_interview_date ? detail.apply_interview_date?.split('T').length > 1 ?  detail.apply_interview_date?.split('T')[0].split('-')[2]+'/'+detail.apply_interview_date?.split('T')[0].split('-')[1]+'/'+detail.apply_interview_date?.split('T')[0].split('-')[0] : detail.apply_interview_date?.split('-')[2]+'/'+detail.apply_interview_date?.split('-')[1]+'/'+detail.apply_interview_date?.split('-')[0] : '',
                        apply_interview_time: detail.apply_interview_time,
                        apply_onboarding_skso_acc: detail.apply_onboarding_skso_acc,
                        apply_da_date: detail.apply_da_date ? detail.apply_da_date?.split('T').length > 1 ? detail.apply_da_date?.split('T')[0].split('-')[2]+'/'+detail.apply_da_date?.split('T')[0].split('-')[1]+'/'+detail.apply_da_date?.split('T')[0].split('-')[0] : detail.apply_da_date?.split('-')[2]+'/'+detail.apply_da_date?.split('-')[1]+'/'+detail.apply_da_date?.split('-')[0] : '',
                        apply_da_time: detail.apply_da_time,
                        apply_da_result: detail.apply_da_result	,
                        onboarding_rtw_check: detail.onboarding_rtw_check,
                        onboarding_bgc_completed: detail.onboarding_bgc_completed ? detail.onboarding_bgc_completed?.split('T').length > 1 ? detail.onboarding_bgc_completed?.split('T')[0].split('-')[2]+'/'+detail.onboarding_bgc_completed?.split('T')[0].split('-')[1]+'/'+detail.onboarding_bgc_completed?.split('T')[0].split('-')[0] : detail.onboarding_bgc_completed?.split('-')[2]+'/'+detail.onboarding_bgc_completed?.split('-')[1]+'/'+detail.onboarding_bgc_completed?.split('-')[0] :'',
                        onboarding_date: detail.onboarding_date	? detail.onboarding_date?.split('T').length > 1 ? detail.onboarding_date?.split('T')[0].split('-')[2]+'/'+detail.onboarding_date?.split('T')[0].split('-')[1]+'/'+detail.onboarding_date?.split('T')[0].split('-')[0] : detail.onboarding_date?.split('-')[2]+'/'+detail.onboarding_date?.split('-')[1]+'/'+detail.onboarding_date?.split('-')[0] : '',
                        active_utr: detail.active_utr,
                        active_amzl_id: detail.amzl_id,
                        active_pay_cycle: detail.active_pay_cycle,
                        active_co_name: detail.active_co_name,
                        active_co_reg: detail.active_co_reg,
                        active_co_vat: detail.active_co_vat,
                        active_bank_name: detail.active_bank_name,
                        active_bank: detail.active_bank,
                        active_bank_ref: detail.active_bank_ref,
                        active_sort_code: detail.active_bank_sort_code,
                        active_account_no: detail.active_bank_account_no,
                        audit_dl_no: detail.audit_dl_no,
                        audit_dl_expiry: detail.audit_dl_expiry ? detail.audit_dl_expiry?.split('T').length > 1 ? detail.audit_dl_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_dl_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_dl_expiry?.split('T')[0].split('-')[0] : detail.audit_dl_expiry?.split('-')[2]+'/'+detail.audit_dl_expiry?.split('-')[1]+'/'+detail.audit_dl_expiry?.split('-')[0] : '',
                        audit_dl_origin: detail.audit_dl_origin,
                        audit_dl_points: detail.audit_dl_points,
                        audit_dl_endorsements: detail.audit_dl_endorsements,
                        audit_dl_check_date: detail.audit_dl_check_date ? detail.audit_dl_check_date?.split('T')[0].length > 0 ? detail.audit_dl_check_date?.split('T')[0].split('-')[2]+'/'+detail.audit_dl_check_date?.split('T')[0].split('-')[1]+'/'+detail.audit_dl_check_date?.split('T')[0].split('-')[0] : detail.audit_dl_check_date?.split('-')[2]+'/'+detail.audit_dl_check_date?.split('-')[1]+'/'+detail.audit_dl_check_date?.split('-')[0] : '',
                        audit_rtw1: detail.audit_rtw1,
                        audit_rtw1_doc: detail.audit_rtw1_doc,
                        audit_rtw1_expiry: detail.audit_rtw1_expiry ? detail.audit_rtw1_expiry?.split('T').length > 1 ? detail.audit_rtw1_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_rtw1_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_rtw1_expiry?.split('T')[0].split('-')[0] : detail.audit_rtw1_expiry?.split('-')[2]+'/'+detail.audit_rtw1_expiry?.split('-')[1]+'/'+detail.audit_rtw1_expiry?.split('-')[0] : '',
                        audit_rtw2: detail.audit_rtw2,
                        audit_rtw2_doc: detail.audit_rtw2_doc,
                        audit_rtw2_expiry: detail.audit_rtw2_expiry ? detail.audit_rtw2_expiry?.split('T').length > 1 ? detail.audit_rtw2_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_rtw2_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_rtw2_expiry?.split('T')[0].split('-')[0] : detail.audit_rtw2_expiry?.split('-')[2]+'/'+detail.audit_rtw2_expiry?.split('-')[1]+'/'+detail.audit_rtw2_expiry?.split('-')[0] : '',
                        audit_rtw_check: detail.audit_rtw_check,
                        updatedBy: detail.updatedBy,
                        notes: detail.notes,
                        offboarding_request_by: detail.offboarding_request_by,
                        offboarding_date: detail.offboarding_date ? new Date(detail.offboarding_date).toLocaleDateString('en-GB') : '',
                        offboarding_reason: detail.offboarding_reason,
                        offboarding_badge_status: detail.offboarding_badge_status,
                        offboarding_security_aware: detail.offboarding_security_aware,
                        offboarding_action_by: detail.offboarding_action_by
                    }
                    setSearch(oldSearch =>[...oldSearch, data])
                }
            })
    }
    const handleClick = (params) =>{
        if(params.field == 'account_no'){
            console.log(params.row)
        }
    }
    const handlePending = (e) =>{
        console.log(pending)
        if(pending == false){
            setPending(true)
        }else{
            setPending(false)
        }

    }
    const handleOnboarding = (e) =>{
        if(onboarding == false){
            setOnboarding(true)
        }else{
            setOnboarding(false)
        } 
    }
    const handleOnboarded = (e) =>{
        if(onboarded == false){
            setOnboarded(true)
        }else{
            setOnboarded(false)
        } 
    }
    const handleInactive = (e) =>{
        if(inactive == false){
            setInactive(true)
        }else{
            setInactive(false)
        }
    }
    const handleOffboarded = (e) =>{
        if(offBoarded == false){
            setOffBoarded(true)
        }else{
            setOffBoarded(false)
        }
    }
    
    useEffect(()=>{
        
        setFilter([pending, onboarding, onboarded, offBoarded])
    },[pending, onboarded, onboarding, offBoarded, inactive])
    useEffect(() => {
        console.log(filter)
        let xDetails = []
        userDetails?.map(detail =>{
            
            if((filter[0] == true && detail.status == 'Pending') || (filter[1] == true && detail.status == 'Onboarding') || (filter[2] == true && detail.status == 'Active') || (filter[3] == true && detail.status == 'Offboarded')|| (filter[4] == true && detail.status == 'Inactive') ){
                let data = {
                    id: detail.entry, 
            status: detail.status,
            account_no: detail.account_no, 
            station: detail.station,
            skso_role: detail.skso_role,
            name: detail.name,
            email: detail.email,
            phone: detail.phone,
            address1: detail.address1, 
            address2: detail.address2,
            town: detail.town,
            county: detail.county,
            post_code: detail.post_code,
            nationality: detail.nationality,
            dob: detail.dob ? detail.dob?.split('T').length > 1 ? detail.dob?.split('T')[0]?.split('-')[2]+'/'+detail.dob?.split('T')[0]?.split('-')[1]+'/'+detail.dob?.split('T')[0]?.split('-')[0] : detail.dob?.split('-')[2] + '/' +detail.dob?.split('-')[1]+ '/'+detail.dob?.split('-')[0] : '',
            age: detail.age,
            nino: detail.nino,
            vehicle_source: detail.vehicle_source, 
            dl_origin: detail.dl_origin,
            dl_no: detail.dl_no,
            dl_expiry: detail.dl_expiry ? detail.dl_expiry?.split('T').length > 1 ? detail.dl_expiry?.split('T')[0].split('-')[2]+'/'+detail.dl_expiry?.split('T')[0].split('-')[1]+'/'+detail.dl_expiry?.split('T')[0].split('-')[0] : detail.dl_expiry?.split('-')[2]+'/'+detail.dl_expiry?.split('-')[1]+'/'+detail.dl_expiry?.split('-')[0] : '',
            dl_points: detail.dl_points,
            apply_interview_date: detail.apply_interview_date ? detail.apply_interview_date?.split('T').length > 1 ?  detail.apply_interview_date?.split('T')[0].split('-')[2]+'/'+detail.apply_interview_date?.split('T')[0].split('-')[1]+'/'+detail.apply_interview_date?.split('T')[0].split('-')[0] : detail.apply_interview_date?.split('-')[2]+'/'+detail.apply_interview_date?.split('-')[1]+'/'+detail.apply_interview_date?.split('-')[0] : '',
            apply_interview_time: detail.apply_interview_time,
            apply_onboarding_skso_acc: detail.apply_onboarding_skso_acc,
            apply_da_date: detail.apply_da_date ? detail.apply_da_date?.split('T').length > 1 ? detail.apply_da_date?.split('T')[0].split('-')[2]+'/'+detail.apply_da_date?.split('T')[0].split('-')[1]+'/'+detail.apply_da_date?.split('T')[0].split('-')[0] : detail.apply_da_date?.split('-')[2]+'/'+detail.apply_da_date?.split('-')[1]+'/'+detail.apply_da_date?.split('-')[0] : '',
            apply_da_time: detail.apply_da_time,
            apply_da_result: detail.apply_da_result	,
            onboarding_rtw_check: detail.onboarding_rtw_check,
            onboarding_bgc_completed: detail.onboarding_bgc_completed ? detail.onboarding_bgc_completed?.split('T').length > 1 ? detail.onboarding_bgc_completed?.split('T')[0].split('-')[2]+'/'+detail.onboarding_bgc_completed?.split('T')[0].split('-')[1]+'/'+detail.onboarding_bgc_completed?.split('T')[0].split('-')[0] : detail.onboarding_bgc_completed?.split('-')[2]+'/'+detail.onboarding_bgc_completed?.split('-')[1]+'/'+detail.onboarding_bgc_completed?.split('-')[0] :'',
            onboarding_date: detail.onboarding_date	? detail.onboarding_date?.split('T').length > 1 ? detail.onboarding_date?.split('T')[0].split('-')[2]+'/'+detail.onboarding_date?.split('T')[0].split('-')[1]+'/'+detail.onboarding_date?.split('T')[0].split('-')[0] : detail.onboarding_date?.split('-')[2]+'/'+detail.onboarding_date?.split('-')[1]+'/'+detail.onboarding_date?.split('-')[0] : '',
            active_utr: detail.active_utr,
            active_amzl_id: detail.amzl_id,
            active_pay_cycle: detail.active_pay_cycle,
            active_co_name: detail.active_co_name,
            active_co_reg: detail.active_co_reg,
            active_co_vat: detail.active_co_vat,
            active_bank_name: detail.active_bank_name,
            active_bank: detail.active_bank,
            active_bank_ref: detail.active_bank_ref,
            active_sort_code: detail.active_bank_sort_code,
            active_account_no: detail.active_bank_account_no,
            audit_dl_no: detail.audit_dl_no,
            audit_dl_expiry: detail.audit_dl_expiry ? detail.audit_dl_expiry?.split('T').length > 1 ? detail.audit_dl_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_dl_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_dl_expiry?.split('T')[0].split('-')[0] : detail.audit_dl_expiry?.split('-')[2]+'/'+detail.audit_dl_expiry?.split('-')[1]+'/'+detail.audit_dl_expiry?.split('-')[0] : '',
            audit_dl_origin: detail.audit_dl_origin,
            audit_dl_points: detail.audit_dl_points,
            audit_dl_endorsements: detail.audit_dl_endorsements,
            audit_dl_check_date: detail.audit_dl_check_date ? detail.audit_dl_check_date?.split('T')[0].length > 0 ? detail.audit_dl_check_date?.split('T')[0].split('-')[2]+'/'+detail.audit_dl_check_date?.split('T')[0].split('-')[1]+'/'+detail.audit_dl_check_date?.split('T')[0].split('-')[0] : detail.audit_dl_check_date?.split('-')[2]+'/'+detail.audit_dl_check_date?.split('-')[1]+'/'+detail.audit_dl_check_date?.split('-')[0] : '',
            audit_rtw1: detail.audit_rtw1,
            audit_rtw1_doc: detail.audit_rtw1_doc,
            audit_rtw1_expiry: detail.audit_rtw1_expiry ? detail.audit_rtw1_expiry?.split('T').length > 1 ? detail.audit_rtw1_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_rtw1_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_rtw1_expiry?.split('T')[0].split('-')[0] : detail.audit_rtw1_expiry?.split('-')[2]+'/'+detail.audit_rtw1_expiry?.split('-')[1]+'/'+detail.audit_rtw1_expiry?.split('-')[0] : '',
            audit_rtw2: detail.audit_rtw2,
            audit_rtw2_doc: detail.audit_rtw2_doc,
            audit_rtw2_expiry: detail.audit_rtw2_expiry ? detail.audit_rtw2_expiry?.split('T').length > 1 ? detail.audit_rtw2_expiry?.split('T')[0].split('-')[2]+'/'+detail.audit_rtw2_expiry?.split('T')[0].split('-')[1]+'/'+detail.audit_rtw2_expiry?.split('T')[0].split('-')[0] : detail.audit_rtw2_expiry?.split('-')[2]+'/'+detail.audit_rtw2_expiry?.split('-')[1]+'/'+detail.audit_rtw2_expiry?.split('-')[0] : '',
            audit_rtw_check: detail.audit_rtw_check,
            updatedBy: detail.updatedBy,
            notes: detail.notes,
            offboarding_request_by: detail.offboarding_request_by,
            offboarding_date: detail.offboarding_date ? new Date(detail.offboarding_date).toLocaleDateString('en-GB') : '',
            offboarding_reason: detail.offboarding_reason,
            offboarding_badge_status: detail.offboarding_badge_status,
            offboarding_security_aware: detail.offboarding_security_aware,
            offboarding_action_by: detail.offboarding_action_by
                }
                xDetails.push(data)
            }
            setFiltered(xDetails)
        })
    },[filter])

    return(
        <>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '60px'}}>Associates Archive</h3>
            { logCheck  ? <>
                {/*<div className="searchBar fixed flex">
                    <input type="text" name='search' className="fullInput" placeholder="search user" onChange={e => handleSearch(e)}  />
                   
                </div>*/}
                {search.length == 0 && filtered.length == 0 ?
                    <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:0 }}>
                    <StripedDataGridPro
                    pagination
                // checkboxSelection
                    //onRowSelectionModelChange={params => setSelected(params)}
                    rows={display}
                    
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        //pinnedColumns: { left: ['name'], right: ['actions'] } 
                        },
                    }}
                    sx={{fontSize: '16px', fontFamily: ''}}
                    pageSizeOptions={[100]}
                    //onRowClick={params => handleRow(params)}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    loading={loading}
                    //{...data}
                    //processRowUpdate={handleCellEdit}
                    onCellClick={handleClick}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    />
                </Box>: search.length > 0 ?
                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:0 }}>
                    <StripedDataGridPro
                    pagination
                // checkboxSelection
                    //onRowSelectionModelChange={params => setSelected(params)}
                    rows={search}
                    
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        //pinnedColumns: { left: ['name'], right: ['actions'] } 
                        },
                    }}
                    sx={{fontSize: '16px', fontFamily: ''}}
                    pageSizeOptions={[100]}
                    //onRowClick={params => handleRow(params)}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    loading={loading}
                    //{...data}
                    //processRowUpdate={handleCellEdit}
                    onCellClick={handleClick}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    />
                </Box>: filtered.length > 0 ?
                    <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:0 }}>
                    <StripedDataGridPro
                    pagination
                // checkboxSelection
                    //onRowSelectionModelChange={params => setSelected(params)}
                    rows={filtered}
                    
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        //pinnedColumns: { left: ['name'], right: ['actions'] } 
                        },
                    }}
                    sx={{fontSize: '16px', fontFamily: ''}}
                    pageSizeOptions={[100]}
                    //onRowClick={params => handleRow(params)}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    loading={loading}
                    //{...data}
                    //processRowUpdate={handleCellEdit}
                    onCellClick={handleClick}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    />
                </Box>:''
                }
            </>:
            <>
                <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
            </>
            }
        </>
    )
}
export default ArchiveData;